import React, { memo, useState, createRef } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import Modal from "../../modal";
import CustomInput from "../../datepicker";

import { formatCurrency, primeirosNomes, saveCookie, width, getStatus, getOperacoes, getValoresProposta } from "../../../helpers/general";
import { getData, sendData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import { Pagination, FilterSelect, InputLabelAlert, } from "../../layout";
import Collapse from "../../layout/Collapse";
import { connect } from "react-redux";
import { setProposta, setCliente } from "../../../store/actions/dados";
import * as moment from "moment";

import "./../../../css/propostas.css";

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    setClickedTr,
    setDateVisible,
    setFinalDate,
    setStartDate,
    sortArrObj,
    proposta_filter,
    handleProposta,
    detailedShow,
    actualPage,
    handlePage,
    finalDate,
    startDate,
    dateVisible,
    clickedTr,
    multSelect,
    total,
    context,
    setModal,
    handlePesquisar,
    handleMarcarTodos,
    qtdLinesPage,
    setQtdLinesPage,
    totais
  } = props;
  const periodRef = createRef();
  const fieldRef = createRef();
  const pesquisaRef = createRef();
  const [collapsed, setCollapsed] = useState(true)
  const arrayPagination = (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) ? [10, 20, 30, 40, 50, 100, 500,] : [10, 20, 30, 50, 100]

  function handleClearOptions() {
    if (periodRef && periodRef.current) periodRef.current.value = "todos";
    if (fieldRef && fieldRef.current) fieldRef.current.value = "todos";
    props.handleClearOptions();

    console.log("handleClearOptions: ", pesquisaRef?.current)
    if (pesquisaRef && pesquisaRef.current) pesquisaRef.current.focus();
  }

  function handleCollapse() {
    saveCookie({ label: "filter_prop_collapse", content: !collapsed });
    setCollapsed(!collapsed)
  }

  function handleQtdLinesPage(e) {
    // saveCookie({ label: "qtdLinesPage", content: e.currentTarget.value });
    setQtdLinesPage(e.currentTarget.value)
  }

  async function handleAlterarProposta(prop, contexto) {
    console.log("portabilidades - handleClickTr(x): ", { prop, contexto });
    props.alterarProposta(prop);
    let uuid = prop.uuid || prop.uuid

    if (['ondblclick'].indexOf(contexto) >= 0)
      await sendData({ uri: uriList('acompanharProposta'), content: { uuid }, method: 'POST' })
        .then(res => {
          res["multSelect"] = prop.multSelect
          props.alterarProposta(res);
          console.log("portabilidades - handleClickTr(z): ", res);
        })
        .catch(e => {
        });
  }

  return (
    <>
      <Collapse isOpen={collapsed} hideHeader title={width('', true) >= 800 && width('', true) <= 1300 ? "" : "Filtros"} maxHeigth={400}
        setCollapse={handleCollapse}
        tip={"Clique aqui para ocultar/exibir os filtros de pesquisa"}
        bodyToggle={true} className="col-md-1" />
      {collapsed &&
        <>
          <div className="row">
            <div className={` col-md-2`}>
              <label className="m-0 font-90">Período</label>
              <select
                ref={periodRef}
                onChange={(e) => {
                  setDateVisible(e.currentTarget.value);
                  if (e.currentTarget.value === "todos") {
                    handleOption(e);
                  }
                }}
                style={{
                  lineHeight: "2.2em",
                  fontSize: "0.8em",
                }}
                className="form-control"
                title="Selecione um periodo para filtrar"
              >
                <option value="todos">Todos</option>
                <option value="cadastro">Data de Cadastro</option>
                <option value="status">Data de Status</option>
                <option value="saldo">Previsão de Saldo</option>
                <option value="pagto">Data de Pagto</option>
              </select>
            </div>

            <div className={width() == "mobile" ? "col-md-12" : "col-md-2"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
              <DatePicker
                locale={pt}
                selected={startDate}
                className="form-control"
                onChange={(date) => setStartDate(date)}
                withPortal
                disabled={dateVisible === "todos"}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
              />
            </div>

            <div className={width() == "mobile" ? "col-md-12" : "col-md-2"} style={{ marginTop: width() != "mobile" ? "23px" : "" }}>
              <DatePicker
                locale={pt}
                selected={finalDate}
                className="form-control"
                onChange={(date) => setFinalDate(date)}
                withPortal
                disabled={dateVisible === "todos"}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
              />
            </div>

            <div className={` col-md-2`}>
              <label className="m-0 font-90">{width('', true) > 800 && width('', true) < 1200 ? "Ocultar Canc." : "Ocultar Canceladas"}</label>
              <FilterSelect
                detailed={{ hideCanceled: ["Sim", "Nao"], }}
                handleOption={props.setHideCanceled}
                ocultarTodos={true}
                title={"Ocultar portabilidades canceladas"}
                value={props.hideCanceled}
                oLabel="hideCanceled"
              />
            </div>

            <div className={` col-md-2`}>
              <label className="m-0 font-90">Status</label>
              <FilterSelect
                detailed={{ posicao: getStatus() }}
                handleOption={handleOption}
                value={props?.filtro?.posicao}
                oLabel="posicao"
              />
            </div>

            <div className={` col-md-2`}>
              <label className="m-0 font-90">Conf. Aprovada</label>
              <FilterSelect
                // detailed={filterOptions}
                detailed={{ conformidadeapv: ["Sim", "Nao"], }}
                handleOption={handleOption}
                value={props?.filtro?.conformidadeapv}
                oLabel="conformidadeapv"
              />
            </div>

            <div className={` col-md-2`}>
              <label className="m-0 font-90">A. Averbação</label>
              <FilterSelect
                // detailed={filterOptions}
                detailed={{ averbacao_solicitada: ["Sim", "Nao"], }}
                handleOption={handleOption}
                title={"Propostas Aguardando Averbação"}
                value={props?.filtro?.averbacao_solicitada}
                oLabel="averbacao_solicitada"
              />
            </div>

            <div className={` col-md-2`}>
              <label className="m-0 font-90">Convênio</label>
              <FilterSelect
                detailed={{ convenio: props.convenios }}
                handleOption={handleOption}
                showCarregando={true}
                value={props?.filtro?.convenio}
                oLabel="convenio"
              />
            </div>

            {
              <>
                {props.agentes &&
                  <div className={` col-md-2`}>
                    <label className="m-0 font-90">Agente</label>
                    <FilterSelect
                      detailed={{ agente: props.agentes }}
                      handleOption={handleOption}
                      showCarregando={true}
                      value={props?.filtro?.agente}
                      oLabel="agente"
                    />
                  </div>
                }

                {(['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0) &&
                  <div className={` col-md-2`}>
                    <label className="m-0 font-90">Agente Master</label>
                    <FilterSelect
                      detailed={{ agentemaster: props.agentesMaster }}
                      handleOption={handleOption}
                      showCarregando={true}
                      value={props?.filtro?.agentemaster}
                      oLabel="agentemaster"
                    />
                  </div>}
              </>
            }

            <div className={` col-md-2`}>
              <label className="m-0 font-90">Documentos Aprovados</label>
              <FilterSelect
                detailed={{ documentosaprovados: ["Sim", "Nao"], }}
                handleOption={handleOption}
                showCarregando={true}
                value={props?.filtro?.documentosaprovados}
                oLabel="documentosaprovados"
              />
            </div>
          </div>
        </>}
      <div className={`row py-2`}>
        <div className={` col-md-2`}>
          <label className="m-0 font-90">Valor de Proposta</label>
          <FilterSelect
            detailed={{ valorproposta: getValoresProposta(), }}
            handleOption={handleOption}
            value={props?.filtro?.valorproposta}
            naoOrdenar={true}
            oLabel="valorproposta"
          />
        </div>
        <div className={`col-md-4`}>
          <InputLabelAlert
            className="m-0 font-90"
            ref={pesquisaRef}
            type="text"
            label={width('', true) > 800 && width('', true) < 1250 ? "Chave Pesq." : "Chave de Pesquisa..."}
            id="proposta_filter"
            title={"Informe a chave de pesquisa para pesquisar"}
            cifrao={false}
            handle={handleProposta}
            value={proposta_filter}
            focus={true}
          />
        </div>
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Campo</label>
          <FilterSelect
            detailed={{
              campoPesquisa: ["Cpf", "Cliente",
                "Agente", "Agente Master", "Operador", "Tabela", "Convênio",
                "Número da Ccb", "Operação", "Status", "UUID Proposta", "Codigo Banco", "Identificador Banco"],
            }}
            handleOption={props.setCampoPesquisa}
            title={"Escolha um campo para pesquisar exclusivamente por ele"}
            value={props.campoPesquisa}
            ocultarTodos={true}
            naoOrdenar={true}
            oLabel="campoPesquisa"
          />
        </div>
        <div className={`col-md-3`}>
          <button
            onClick={handlePesquisar}
            style={{
              backgroundColor: "#17a2b8",
              color: "white",
              width: width() == "mobile" ? "100%" : '40%',
              height: width() == "mobile" ? "50%" : "60%",
              marginTop: width() == "mobile" ? "10px" : "23px"
            }}
            title={"Clique aqui para realizar a busca/pesquisa das portabilidades"}
            className="btn btn-sm "
          >
            <strong>Pesquisar</strong>
          </button>
          <button
            onClick={handleClearOptions}
            style={{
              // width: (dateVisible !== "todos" ? "50%" : "100%") 
              width: width() == "mobile" ? "100%" : '40%',
              height: width() == "mobile" ? "50%" : "60%",
              marginTop: width() == "mobile" ? "5px" : "23px",
              marginLeft: width() == "mobile" ? "" : "5px",
            }}
            className="btn btn-sm btn-secondary"
            title={"Clique aqui para limpar os filtros de pesquisa"}
          >
            Limpar
          </button>
        </div>
        {/* <div className={`col-md-2`}>
          
        </div> */}
      </div>

      <div className="table1 mt-4">
        {(['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0) &&
          < div className="mb-1" style={{
            border: "0.5px solid",
            borderColor: "lightGray",
            borderRadius: "2px",
            padding: "5px",
          }}>
            <button
              title={"Marcar/Desmarcar todas as portabilidades da página"}
              onClick={() => handleMarcarTodos(false)}
              className="mdi mdi-checkbox-marked-outline"
              style={{
                border: "0.5px solid",
                borderColor: "Gray",
                borderRadius: "2px",
              }}
            >
            </button>

            <button
              title={"Marcar/Desmarcar todas as portabilidades"}
              onClick={() => handleMarcarTodos(true)}
              disabled={true}
              className="mdi mdi-checkbox-multiple-marked-outline  ml-1"
              style={{
                border: "0.5px solid",
                borderColor: "Gray",
                borderRadius: "2px",
              }}
            >
            </button>

          </div>}
        <table
          className="table table-bordered table-striped table-hover"
          style={{ marginBottom: "0px" }}
        >
          {props.buscando && <div>Abrindo portabilidades, aguarde...</div>}
          <thead>
            <tr
              title="Clique para ordenar"
              style={{ backgroundColor: "#f2f2f2" }}
            >
              <th
                scope="col"
                className="font-80"
                onClick={() => sortArrObj("proposta_id", "numeric")}
              >
                #
              </th>
              <th
                scope="col"
                className="font-80"
                onClick={() => sortArrObj("cliente", "string")}
              >
                Cliente
              </th>
              <th
                scope="col"
                className="font-80"
                onClick={() => sortArrObj("cpf", "numeric")}
              >
                CPF
              </th>
              {/* <th
                scope="col"
                className="font-80"
                onClick={() => sortArrObj("celular", "string")}
              >
                Celular
              </th> */}
              <th
                scope="col"
                className="font-80"
                onClick={() => sortArrObj("created_at", "date")}
              >
                Cadastro
              </th>
              <th
                scope="col"
                className="text-right font-80"
                onClick={() => sortArrObj("valor_total", "numeric")}
              >
                Total
              </th>
              <th
                scope="col"
                className="text-right font-80"
                onClick={() => sortArrObj("prazo", "numeric")}
              >
                Prazo
              </th>
              <th
                scope="col"
                className="text-right font-80"
                onClick={() => sortArrObj("valor_parcelas", "numeric")}
              >
                Parcelas
              </th>
              <th
                className="font-80"
                onClick={() => sortArrObj("operacao", "string")}
                scope="col"
              >
                Operação
              </th>
              <th
                className="font-80"
                onClick={() => sortArrObj("tabela", "string")}
                scope="col"
              >
                Tabela
              </th>
              <th
                className="font-80"
                onClick={() => sortArrObj("agente", "string")}
                scope="col"
              >
                Agente
              </th>
              {/* <th
                className="font-80"
                onClick={() => sortArrObj("operador", "string")}
                scope="col"
              >
                Operador
              </th> */}
              <th
                className="font-80"
                scope="col"
                onClick={() => sortArrObj("posicao", "string")}
              >
                Status
              </th>
              <th
                className="font-80"
                scope="col"
                onClick={() => sortArrObj("status_data", "date")}
              >
                Dt. Status
              </th>
            </tr>
          </thead>
          <tbody>
            {detailedShow.length > 0 ? (
              detailedShow.map(
                (proposta, i) =>
                (
                  <tr
                    onContextMenu={async (e) => {
                      // console.log("onContextMenu - Proposta: ", proposta);
                      let prop = proposta;
                      prop["multSelect"] = multSelect;
                      handleAlterarProposta(prop, 'context')

                      e.preventDefault();
                      setClickedTr(proposta.uuid, { context: true });
                      context(e, true, proposta);
                    }}
                    onClick={(e) => {
                      // console.log("onClick - Proposta: ", proposta);
                      let prop = proposta;
                      prop["multSelect"] = multSelect;
                      handleAlterarProposta(prop, 'onclick')
                      setClickedTr(proposta.uuid, e);
                    }}
                    onDoubleClick={() => {
                      if (props.DblClick) {
                        props.alterarCliente(null);
                        let prop = proposta;
                        prop["multSelect"] = multSelect;
                        handleAlterarProposta(prop, 'ondblclick')
                        props.DblClick(proposta.uuid);
                      }
                    }}

                    className={`${multSelect.indexOf(proposta.uuid) >= 0 ||
                      clickedTr === proposta.uuid
                      ? "bg-secondary text-light"
                      : proposta.posicao?.toLowerCase() == "cancelada"
                        ? "disabled"
                        : ""
                      }`}
                    key={proposta.uuid}
                  >
                    <td title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>{proposta.proposta_id}</td>
                    <td title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>
                      <i
                        onContextMenu={(e) => {
                          // console.log('reducer - portabilidadesform(onContextMenu-2): ', proposta)
                          let prop = proposta;
                          prop["multSelect"] = multSelect;
                          handleAlterarProposta(prop, 'oncontextmenu')
                          // props.alterarProposta(prop);

                          e.preventDefault();
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          let prop = proposta;
                          prop["multSelect"] = multSelect;
                          handleAlterarProposta(prop, 'onclick-td')

                          e.preventDefault();
                          context(e, true, proposta);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                        title="Opções"
                        className="mdi mdi-cog float-left text-danger mr-2"
                      ></i>
                      {proposta.cliente && proposta.cliente.substr(0, 30)}
                      <i
                        onClick={() => {
                          let prop = proposta;
                          prop["multSelect"] = multSelect;
                          handleAlterarProposta(prop, 'onclick-td')

                          setModal(
                            <Modal
                              title="Dados do Cliente"
                              onClick={() => setModal(false)}
                              page={true}
                              text={[
                                `Nome: ${proposta.cliente || "Desconhecido"}`,
                                `CPF: ${proposta.cpf || "Desconhecido"}`,
                                `Celular/Telefone: ${proposta.celular ||
                                proposta.telefone ||
                                "Desconhecido"
                                }`,
                                // `Data de Cadastro: ${new Date(proposta.created_at.toString()).toLocaleDateString() || 'Desconhecido'}`,
                                `Data de Cadastro: ${moment(proposta.created_at).format(
                                  "DD/MM/YYYY"
                                ) || "Desconhecido"
                                }`,
                                `Valor Empréstimo: ${formatCurrency(proposta.valor_liquido) ||
                                "Desconhecido"
                                }`,
                                `Prazo: ${proposta.prazo || "Desconhecido"}`,
                                `Valor Parcelas: ${formatCurrency(proposta.valor_parcelas) ||
                                "Desconhecido"
                                }`,
                                `Tabela: ${proposta.tabela || "Desconhecido"
                                }`,
                                `Agente: ${proposta.agente || "Desconhecido"
                                }`,
                                `Operador: ${proposta.operador || "Desconhecido"
                                }`,
                                `Status: ${proposta.posicao || "Desconhecido"
                                }`,
                                // `Data de Status: ${new Date(proposta.status_data.toString()).toLocaleDateString() || 'Desconhecido'}`,
                                `Data de Status: ${moment(proposta.status_data).format(
                                  "DD/MM/YYYY"
                                ) || "Desconhecido"
                                }`,
                                <button
                                  onClick={() => setModal(false)}
                                  className="btn btn-lg btn-outline-secondary my-3"
                                >
                                  Fechar
                                </button>,
                              ]}
                            />
                          );
                        }}
                        className="mdi mdi-account-box text-danger d-md-none float-right"
                      />
                    </td>
                    <td style={{ minWidth: "110px", maxWidth: "110px" }} title={`CPF: ${proposta.cpf || "Desconhecido"}`}>
                      {proposta.cpf}
                    </td>
                    {/* <td style={{ minWidth: "110px", maxWidth: "110px" }} title={`Celular: ${proposta.celular || "Desconhecido"}`}>
                      {proposta.celular}
                    </td> */}
                    <td title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>
                      {moment(proposta.created_at).format("DD/MM/YYYY")}
                    </td>
                    <td className="text-right" title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>
                      {formatCurrency(
                        proposta.operacao.toLowerCase() ==
                          "refinanciamento" ||
                          proposta.tipo_convenio == "ORIGINAL" ||
                          proposta.tipo_convenio == "FGTS"
                          ? proposta.valor_liquido
                          : proposta.valor_total
                      )}
                    </td>
                    <td
                      className="text-right"
                      style={{ minWidth: "40px", maxWidth: "40px" }}
                      title={`Cliente: ${proposta.cliente || "Desconhecido"}`}
                    >
                      {proposta.prazo}
                    </td>
                    <td className="text-right" title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>
                      {formatCurrency(proposta.valor_parcelas)}
                    </td>
                    <td style={{ minWidth: "140px", maxWidth: "140px" }} title={`Operação: ${proposta.operacao || "Desconhecido"}`}>
                      {proposta.operacao}
                    </td>
                    <td style={{ minWidth: "180px", maxWidth: "180px" }} title={`Tabela: ${proposta.tabela || "Desconhecido"}`}>
                      {primeirosNomes(proposta.tabela, 3)}
                    </td>
                    <td style={{ minWidth: "120px", maxWidth: "120px" }} title={`Agente: ${proposta.agente || "Desconhecido"}`}>
                      {primeirosNomes(proposta.agente, 2).substring(0, 12)}
                    </td>
                    {/* <td style={{ minWidth: "120px", maxWidth: "120px" }} title={`Operador: ${proposta.operador || "Desconhecido"}`}>
                      {primeirosNomes(proposta.operador, 2).substring(0, 12)}
                    </td> */}
                    <td
                      className="table-vertical"
                      style={{ maxWidth: "100px" }}
                      title={`Cliente: ${proposta.cliente || "Desconhecido"}`}
                    >
                      {proposta.posicao}
                    </td>
                    <td title={`Cliente: ${proposta.cliente || "Desconhecido"}`}>
                      {moment(
                        proposta.status_data
                          ? proposta.status_data
                          : proposta.created_at
                      ).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td>Sem dados</td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="2" className="text-left">
                Total de Propostas: {totais?.qtd_propostas}
              </td>
              <td className="text-right font-weight-bold " colSpan="2">
                Total Geral
              </td>
              <td className="text-right font-weight-bold ">
                {parseFloat(totais?.total_pagto || totais?.total || 0).toLocaleString("pt-br", { minimumFractionDigits: 2 })}
              </td>
              <td colSpan="7"></td>
            </tr>
          </tfoot>
        </table>
      </div >
      {
        < div className="d-flex" >
          <Pagination
            detailed={detailedShow}
            actual={actualPage}
            changePage={handlePage}
            qtdLinesPage={qtdLinesPage}
            lastPage={totais?.lastPage}
          />
          <div className="page-item ml-1" style={{ padding: "0.05rem" }}>
            <select
              defaultValue={qtdLinesPage}
              name="qtdLinesPage"
              id="qtdLinesPage"
              onChange={handleQtdLinesPage}
              style={{
                width: "75px",
                height: "100%",
                textAlign: "center",
                backgroundColor: "#EBEEE9",
                borderRadius: "4px",
                border: "1.5px solid #727476",
                backgroundImage:
                  "linear-gradient(to top, #D1D3D4, #F7F8F9 90%)",
              }}
            >
              {arrayPagination.map((lines, i) => (
                <option
                  key={`lines-${i}`}
                  label={lines}
                  value={lines}
                >
                  {lines}
                </option>
              ))}
            </select>
          </div>
        </div >
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(memo(Form));

