import React, { useState, useEffect, useCallback } from "react";
import Form from "../../components/form/admin/Documentos";
import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";

import {
  DefaultOrder,
  LOGRADOUROS,
  HORARIOLIST,
  sortByString,
  sortByNumber,
  compareDate,
} from "../../helpers/general";
function Documentos({ ...props }) {
  let logradouros = LOGRADOUROS.replace(/\s/g, "").split(",");
  const [documentosC, setDocumentosC] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [documento_filter, setDocumentoFilter] = useState("");
  const [filterOptions, setFilterOptions] = useState(false);
  const [ascDesc, setAscDesc] = useState(true);
  const [modal, setModal] = useState(false);
  const [clickedTr, setClickedTr] = useState("");

  const handleOption = (e) => {
    let label = e.currentTarget.dataset.label || e.currentTarget.name;
    let value = e.currentTarget.value;
    let newDetailed;
    console.log("Documentos - handleOption", value);

    if (value !== "todos" && value !== "") {
      newDetailed = documentosC.filter((Documentos) => {
        if (label === "documento_filter") {
          let result =
            (Documentos["cpf"] &&
              value.replace(/\D/g, "") != "" &&
              Documentos["cpf"]
                .replace(/\D/g, "")
                .indexOf(value.replace(/\D/g, "")) >= 0) ||
            (Documentos["nome"] &&
              Documentos["nome"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (Documentos["email"] &&
              Documentos["email"].toLowerCase().indexOf(value.toLowerCase()) >=
              0);
          return result;
        } else return Documentos[label] === value;
      });

      setDocumentos(newDetailed);
      setActualPage(0);
    } else {
      setDocumentos(documentosC);
      setActualPage(0);
    }
  };

  const handleDocumento = (e) => {
    let value = e.currentTarget.value;
    setDocumentoFilter(value);
    handleOption(e);
  };

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  const createFilter = (obj) => {
    let newObj = {
      nome: [],
      cpf: [],
      email: [],
      tipo: [],
      status: [],
      cidade: [],
      uf: [],
    };

    for (let o of obj) {
      for (let i in o) {
        if (newObj[i]) {
          newObj[i].push(o[i]);
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    setFilterOptions(newObj);
  };

  const _Documentos = async () => {
    let uri = uriList("documentos-list");
    await sendData({
      uri,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        console.log("Documentos - res: ", res);
        setDocumentos(res);
        DefaultOrder(
          "Documentos",
          res.map((Documentos) => Documentos),
          setDocumentos
        );
        createFilter(res.map((Documentos) => Documentos));
        setDocumentosC(res);
      })
      .catch((e) => {
        console.log("documentos - Erro ao obter documentos: ", e);
        return false;
      });
  };

  useEffect(() => {
    _Documentos();
  }, []);

  const sortArrObj = (key, type) => {
    let arr = [...Documentos];
    let asc = !ascDesc;

    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    setDocumentos(arr);
  };

  return (
    <>
      <Form
        filterOptions={filterOptions}
        handleOption={handleOption}
        setModal={setModal}
        handleDocumento={handleDocumento}
        setClickedTr={setClickedTr}
        clickedTr={clickedTr}
        setDocumentos={setDocumentos}
        Documentos={documentos}
        DocumentosC={documentosC}
        sortArrObj={sortArrObj}
        actualPage={actualPage}
        handlePage={handlePage}
        logradouros={logradouros}
        horarioList={HORARIOLIST}
        documento_filter={documento_filter}
        callback={_Documentos}
        context={props.context}
        refresh={_Documentos}
      />

      {modal}
    </>
  );
}

export default Documentos;
