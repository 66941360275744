import React from "react";
import Dropzone from "react-dropzone";

import IconImage from "../../../images/cloud.png";
import FileImage from "../../../images/file.png";
// import FileImage from '../../../images/img_file.jpg';

import { width, getCookie } from "../../../helpers/general";
import { InputAlert } from "../../layout";
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA, TOKEN_BANK } from "../../../helpers/config";
import { bancoFornecedor, getItemCrypt } from '../../../helpers/auth'
import { connect } from "react-redux";
import { post } from "axios";

const Form = ({ ...props }) => (
  <>
    <div className="form">
      {width() !== "mobile" ? (
        <Dropzone
          name="selfie"
          onDrop={(acceptedFiles) => {
            try {
              let files = acceptedFiles.map((file, i) => {
                let url = URL.createObjectURL(file);
                let ext = file.name.split(".");

                ext = ext[ext.length - 1];

                file.preview = url;
                file.ext = ext;
                // console.log(file.size)

                if (file.size > 5242880)
                  throw new Error("Tamanho máximo do arquivo é de 5mB!");

                let _dataFile = new Date(file.lastModifiedDate);
                let _dataMin = new Date();
                _dataMin.setDate(_dataMin.getDate() - 2);
                // console.log(_dataFile, _dataMin)
                if (_dataFile < _dataMin)
                  throw new Error("A Selfie deve ser recente!");
                sendDoc(file, props.proposta, props);
                return file;
              });
            } catch (e) {
              // console.log(e)
              props.modal({
                title: "Erro",
                text: [e.message],
              });
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div>
              <p className="font-weight-bold no-margin text-center">
                <HelpButton modal={props.modal} />
              </p>
              <section className="drop-box col-md-8 no-margin">
                <div
                  {...getRootProps({
                    onFocus: (e) => (e.currentTarget.style.outline = "none"),
                  })}
                >
                  <input
                    {...getInputProps({
                      multiple: false,
                      onClick: (e) => props.data.selfie && e.preventDefault(),
                      accept: "image/jpg,image/jpeg,image/png",
                      disabled: props.disabled,
                    })}
                  />
                  <div
                    style={{
                      textAlign: !props.data.selfie && "center",
                      position: "relative",
                      height: "300px",
                    }}
                    className="lead text-info box"
                  >
                    {!props.data.selfie ? (
                      <div style={{ transform: "translateY(100px)" }}>
                        {" "}
                        <img
                          src={IconImage}
                          alt="Cloud-icon"
                          className="img-fluid custom-image d-block"
                          style={{ margin: "10px auto" }}
                        />
                        <span style={{ margin: "0 auto", fontSize: "0.85em" }}>
                          Clique ou arraste o documento
                        </span>{" "}
                      </div>
                    ) : (
                      <JSONObj
                        files={props.data.selfie}
                        del={props.del}
                        mobile={false}
                        name="selfie"
                      />
                    )}
                  </div>
                </div>

                <InputAlert display={props.warning.selfie} field={"Selfie"} />
              </section>
            </div>
          )}
        </Dropzone>
      ) : (
        <div className="mb-5" style={{ transform: "translateY(15%)" }}>
          <HelpButton modal={props.modal} />

          <label
            htmlFor="selfie_input"
            className="drop-box col-md-5 mb-4 text-center"
          >
            {!props.data.selfie ? (
              <>
                <input
                  id="selfie_input"
                  type="file"
                  accept="image/*"
                  disabled={props.disabled}
                  capture="camera"
                  onChange={(e) => {
                    let file = e.currentTarget.files[0];
                    let uri = URL.createObjectURL(file);
                    let ext = file.name.split(".");

                    ext = ext[ext.length - 1];

                    file.preview = uri;
                    file.ext = ext;

                    sendDoc(file, props.proposta, props);

                    // props.change('selfie', file);
                  }}
                  style={{ display: "none" }}
                />
                <div style={{ height: "120px" }} className="lead text-info box">
                  <div style={{ transform: "translateY(25%)" }}>
                    <img
                      src={IconImage}
                      alt="Cloud-icon"
                      className="img-fluid custom-image d-block"
                      style={{ margin: "0 auto" }}
                    />

                    <span style={{ margin: "0 auto", fontSize: "0.85em" }}>
                      Clique Aqui para tirar uma Selfie
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <JSONObj
                thisClass="box"
                files={props.data.selfie}
                del={props.del}
                mobile={true}
                name="selfie"
              />
            )}

            <InputAlert display={props.warning.selfie} field={"Selfie"} />
          </label>
        </div>
      )}
    </div>
  </>
);

const JSONObj = ({ ...props }) => (
  <div
    style={{ height: "100%" }}
    className={`lead text-info text-left ${props.thisClass}`}
  >
    <span
      style={{ padding: "0 0", width: "100%", height: "100%" }}
      className="d-block"
    >
      <a href={props.files[0].preview} download>
        <img
          alt="Preview"
          style={{
            width: !props.mobile ? "40%" : "120px",
            height: props.mobile ? "120px" : "100%",
            float: "left",
          }}
          src={
            props.files[0].ext === "jpg" ||
              props.files[0].ext === "jpeg" ||
              props.files[0].ext === "png"
              ? props.files[0].preview
              : FileImage
          }
        />
      </a>

      <span
        className="text-center span-del d-block"
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.del(props.name);
        }}
        style={{
          fontSize: "0.79em",
          paddingLeft: "5px",
          lineHeight: !props.mobile ? "300px" : "120px",
        }}
      >
        Remover Arquivo
      </span>
    </span>
  </div>
);

const HelpButton = ({ ...props }) => (
  <span
    style={{ position: "relative" }}
    className="font-weight-bold no-margin text-center d-block"
  >
    Tire uma Selfie
    <span
      className="custom-info selfie-info"
      onClick={() =>
        props.modal({
          title: "Saiba mais: Selfie",
          text: [
            "Tire uma foto pessoal sozinho",
            "Aproxime a câmera a uma distância de 20 a 30cm do seu rosto em uma área iluminada e olhe para a câmera do seu celular ao tirar a foto",
          ],
        })
      }
    >
      ?
    </span>
  </span>
);

const sendDoc = (file, uuid, props) => {
  if (props.aguardar) props.aguardar(true);
  //-- Validando data novamente ao enviar(caso o cliente faça a proposta e espere para enviar)
  let _dataFile = new Date(file.lastModifiedDate);
  let _dataMin = new Date();
  _dataMin.setDate(_dataMin.getDate() - 2);
  // console.log(_dataFile, _dataMin)
  if (_dataFile < _dataMin) throw new Error("A Selfie deve ser recente!");

  let dObj = new FormData();
  let [latitude, longitude] = getCookie({ label: "geolocation" })
    ? getCookie({ label: "geolocation" }).split(",")
    : ["", ""];

  dObj.append("tipo", "selfie");
  dObj.append("proposta_uuid", uuid);
  dObj.append("file", file);
  dObj.append("ip", getCookie({ label: "ip" }));
  dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
  dObj.append("data_arquivo", file.lastModifiedDate);
  dObj.append("bancoFornecedor", bancoFornecedor())

  const url = uriList("documentos");
  const config = {
    headers: {
      Accept: "*",
      key: process.env.REACT_APP_KEY || "aZcVK2kl4M1f4jRVtoRPToZy3jl1qUzY",
      "content-type": "multipart/form-data",
      "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
      "captcha": getItemCrypt(TOKEN_CAPTCHA),
    },
  };
  post(url, dObj, config)
    .then((response) => {
      !props.data.selfie && props.change("selfie", file);

      if (props.aguardar) props.aguardar(false);
      if (props.setGravarSelfie) props.setGravarSelfie(true);
    })
    .catch((err) => {
      console.log("DropZone - Error: ", err);
      if (props.aguardar) props.aguardar(false);
    });
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Form);
