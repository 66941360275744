import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


import Form from "../components/form/auth/login";
import "../sass/login.scss";
import { NotificationManager } from "react-notifications";

import { sendMultiPartData, sendData, inactivityTime, getItemCrypt, setItemCrypt } from "../helpers/auth";
import { uriList, TOKEN_KEY, TOKEN_USER, TOKEN_CAPTCHA } from "../helpers/config";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { setUser } from "../store/actions/dados";
import { swalError } from "../components/swal";

let userIni = {
  email: "",
  password: "",
  connected: true,
};

const Login = ({ ...props }) => {
  const history = useHistory();
  const [data, setData] = useState(userIni);
  const [showAlert, setAlert] = useState({
    email: 0,
    password: 0,
  });
  const [loginData, setLoginData] = useState({});
  const [MfaAuthentication, setMfaAuthentication] = useState(false);

  useEffect(() => { }, []);

  const handleData = (e) => {
    let target = e.currentTarget;
    if (target.id === "email")
      setData({ ...data, [target.name]: target.value.toLowerCase() });
    else setData({ ...data, [target.name]: target.value });
    clearAlert(e);
    // console.log(data)
  };

  const handleChecked = (e) => {
    let target = e.currentTarget;
    setData({ ...data, [target.name]: target.checked });

    // console.log(data)
  };

  const clearAlert = (e) => {
    let target = e.currentTarget;
    setAlert({ ...showAlert, [target.name]: 0 });
  };

  const submitmfa = async (e, code) => {
    const content = {
      email: loginData.email || data?.email,
      token: data.tokenMFA,
    };

    console.log("[LOGIN_SUBMITMFA-checarTokenMFA] content: ", content)
    await sendData({ uri: uriList("checarTokenMFA"), content: content, method: "POST" })
      .then((response) => {
        console.log("[LOGIN_SUBMITMFA-checarTokenMFA]-response: ", response)
        if (response.status !== 200 || response.data === undefined) {
          swalError({ title: "Erro ao fazer login", text: "Erro ao verificar token MFA." });
          return false;
        }

        console.log("[LOGIN_SUBMITMFA] response.data: ", response)
        handleLogin(loginData);
      })
      .catch((e) => {
        console.log("[LOGIN_SUBMITMFA-checarTokenMFA]-error: ", e)
        swalError({ title: "Erro ao fazer login", text: "Erro ao verificar token MFA." });

        return false;
      });
  }


  const submit = (e, captcha) => {
    console.log("routes.login.js: submit")
    e.preventDefault();

    if (data.password === "" && data.email === "") {
      setAlert({ email: 1, password: 1 });
      return false;
    }

    if (data.password === "") {
      setAlert({ email: 0, password: 1 });
      return false;
    }

    if (data.email === "") {
      setAlert({ email: 1, password: 0 });
      return false;
    }

    let dObj = new FormData();

    // console.log("routes.login.js: submit - captcha: ", captcha)

    dObj.append("email", data.email);
    dObj.append("password", data.password);
    dObj.append("captcha", captcha);

    console.log("routes.login.js(ok)")

    sendMultiPartData({
      uri: uriList("login"),
      content: dObj,
      signal: null,
      method: "POST",
      errorTitle: "Erro ao fazer login",
      errorMsg: "Api temporariamente indisponível."
    })
      .then((response) => {
        console.log("Erro de Login: \n", e);
        if (
          (response[0] !== undefined && response.status !== 200) ||
          response.data == undefined
        ) {
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);

          if (response[0])
            NotificationManager.error(response[0].message, "Erro!", 2000);
          else NotificationManager.error(response.message, "Erro!", 2000);
          return false;
        }

        console.log("routes.login.js: submit - response.data: ", response.data)

        if (response?.data?.mfa_secret | response?.data?.user?.mfa_secret) {
          setLoginData(response.data);
          setMfaAuthentication(true);
        }
        else
          handleLogin(response.data)
      })
      .catch((e) => {
        setData(userIni);
        console.log("Erro de Login: \n", e);
        if (e.message) NotificationManager.error(e.message, "Erro!", 2000);
        else NotificationManager.error("Erro ao fazer login", "Erro!", 2000);
        return false;
      });
  };

  function handleLogin(_data) {
    const { token, user } = _data;

    props.alterarUser(user);
    setItemCrypt(TOKEN_KEY, token);
    // setItemCrypt(TOKEN_CAPTCHA, captcha)
    setItemCrypt(TOKEN_USER, JSON.stringify(user));
    console.log("login.js: localStorage.setItemCrypt(TOKEN_KEY)")

    history.push("./admin");
    if (data.connected)
      localStorage.setItem("manter_conectado", data.connected);
    inactivityTime(true);
  }

  return (
    <div className="container-md" >
      <div
        style={{ paddingTop: "15%", margin: "auto" }}
        className="wrapper-page"
      >
        <NotificationContainer />
        <Form
          data={data}
          handle={handleData}
          submit={submit}
          submitmfa={submitmfa}
          warning={showAlert}
          checked={handleChecked}
          MfaAuthentication={MfaAuthentication}
          clearMFA={() => setMfaAuthentication(false)}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Login);
