import React, { Component } from "react";
import { withRouter } from "react-router";
import MenuItem from "./MenuItem";
import MenuItemWithChildren from "./MenuItemWithChildren";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import Banner from "../../images/Banner Lateral.png";
import { width } from "../../helpers/general";
import { sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import Whatsappbtn from '../outros/whatsappbtn'

import "./sidebar.css";

class Sidebar extends Component {
  getIdCode = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  temPermissao = (_permissao) => {
    if (this.props?.user?.tipo == "ADMINISTRADOR" || this.props?.user?.tipo == "OPERADOR")
      return true

    const permissoes = this.props.user?.permissoes || this.props.user?.controle_acesso?.permissoes;
    console.log("sidebar.temPermissao(user): ", this.props.user)
    console.log("sidebar.temPermissao(Permissões): ", { permissoes, _permissao })
    if (!permissoes) return false;

    const _temPermissao = permissoes.some(permissao => _permissao.includes(permissao.name))
    console.log("sidebar.temPermissao(_temPermissao): ", _temPermissao)
    return _temPermissao
  };

  getMenu = async (props) => {
    this.data = [
      {
        id: this.getIdCode(),
        url: "#",
        label: "Menu",
        visible: true,
        is_main: true,
      },
      {
        id: this.getIdCode(),
        url: "#",
        visible: ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0,
        class: "waves-effect",
        label: "Cadastros",
        icon: "mdi mdi-plus-circle",
        badgeclass: "badge badge-pill badge-success float-right",
        children: [
          {
            id: this.getIdCode(),
            url: "/admin/pessoas?op=cadastrar",
            label: "Pessoas",
            visible: true,
            icon: "mdi mdi-account-group",
          },
          {
            id: this.getIdCode(),
            url: "/admin/convenios",
            label: "Convenios",
            visible: props.user?.tipo == "ADMINISTRADOR",
            icon: "mdi mdi-office-building",
          },
          {
            id: this.getIdCode(),
            url: "/admin/tabelas",
            label: "Tabelas",
            visible: props.user?.tipo == "ADMINISTRADOR",
            icon: "mdi mdi-table-edit",
          },
          {
            id: this.getIdCode(),
            url: "/admin/coeficientes",
            label: "Coeficientes",
            visible: true,
            icon: "mdi mdi-percent",
          },
          {
            id: this.getIdCode(),
            url: "/admin/emails",
            label: "Emails",
            visible: props.user?.tipo == "ADMINISTRADOR",
            icon: "mdi mdi-email",
          },
          {
            id: this.getIdCode(),
            url: "/admin/usuarios",
            visible: props.user?.tipo == "ADMINISTRADOR",
            label: "Usuários",
            icon: "mdi mdi-account-multiple",
          },
          {
            id: this.getIdCode(),
            url: "/admin/empresas",
            label: "Empresas",
            visible: props.user?.gestor,
            icon: "mdi mdi-office-building",
          },
        ],
      },
      // {
      //   id: this.getIdCode(),
      //   url: "#",
      //   visible: ["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0,
      //   class: "waves-effect",
      //   label: "Conformidade",
      //   icon: "mdi mdi-shield-account-outline",
      //   badgeclass: "badge badge-pill badge-success float-right",
      //   children: [
      //     {
      //       id: this.getIdCode(),
      //       url: "/admin/checkpoint",
      //       visible: true,
      //       label: "CheckPoints",
      //       icon: "mdi mdi-checkbox-multiple-marked",
      //     },
      //     {
      //       id: this.getIdCode(),
      //       url: "/admin/checklist",
      //       visible: true,
      //       label: "CheckLists",
      //       icon: "mdi mdi-checkerboard",
      //     },
      //   ],
      // },
      {
        id: this.getIdCode(),
        url: "/admin",
        class: "waves-effect",
        visible: true,
        label: "Fila de Propostas",
        icon: "mdi mdi-view-dashboard",
        badgeclass: "badge badge-pill badge-success float-right",
      },
      // {
      //   id: this.getIdCode(),
      //   url: "#",
      //   visible:
      //     !props.user?.tipo_credito ||
      //     ["CONSIGNADO", "TODOS"].indexOf(props.user?.tipo_credito) >= 0,
      //   class: "waves-effect",
      //   label: "Esteiras",
      //   icon: "mdi mdi-view-day",
      //   badgeclass: "badge badge-pill badge-success float-right",
      //   children: [
      //     {
      //       id: this.getIdCode(),
      //       url: "/admin/esteira/portabilidade",
      //       class: "waves-effect",
      //       visible: true,
      //       label: "Portabilidade",
      //       icon: "mdi mdi-folder-multiple",
      //       badgeclass: "badge badge-pill badge-success float-right",
      //     },
      //     {
      //       id: this.getIdCode(),
      //       url: "/admin/esteira/novo",
      //       class: "waves-effect",
      //       visible: true,
      //       label: "Contrato Novo",
      //       icon: "mdi mdi-file-outline",
      //       badgeclass: "badge badge-pill badge-success float-right",
      //     },
      //     {
      //       id: this.getIdCode(),
      //       url: "/admin/esteira/refinanciamento",
      //       class: "waves-effect",
      //       visible: true,
      //       label: "Refinanciamento",
      //       icon: "mdi mdi-image-filter-none",
      //       badgeclass: "badge badge-pill badge-success float-right",
      //     },
      //   ],
      // },
      {
        id: this.getIdCode(),
        url: "#",
        visible:
          !props.user?.tipo_credito ||
          ["CONSIGNADO", "TODOS"].indexOf(props.user?.tipo_credito) >= 0,
        class: "waves-effect",
        label: "Crédito Consignado",
        icon: "mdi mdi-cash",
        badgeclass: "badge badge-pill badge-success float-right",
        children: [
          {
            id: this.getIdCode(),
            url: "/admin/novaProposta",
            visible: true,
            label: "Contrato Novo",
            icon: "mdi mdi-file-outline",
          },
          {
            id: this.getIdCode(),
            url: "/admin/Refinanciamento",
            visible: props.user?.tipo != "AGENTE",
            label: "Refinanciamento",
            icon: "mdi mdi-image-filter-none",
          },
          {
            id: this.getIdCode(),
            url: "/admin/Portabilidade",
            visible: true,
            label: "Portabilidade",
            icon: "mdi mdi-content-save",
          },
        ],
      },
      {
        id: this.getIdCode(),
        url: "#",
        visible:
          !props.user?.tipo_credito ||
          ["PESSOAL", "TODOS"].indexOf(props.user?.tipo_credito) >= 0,
        class: "waves-effect",
        label: "Crédito Pessoal",
        icon: "mdi mdi-account-cash",
        badgeclass: "badge badge-pill badge-success float-right",
        children: [
          // {
          //   id: this.getIdCode(),
          //   url: "/admin/creditoPessoal",
          //   visible: true,
          //   label: "Empréstimo Pessoal",
          //   icon: "mdi mdi-file-outline",
          // },
          {
            id: this.getIdCode(),
            url: "/admin/Fgts",
            visible: true,
            label: "Fgts",
            icon: "mdi mdi-nfc-variant",
          },          
        ],
      },
      
      {
        id: this.getIdCode(),
        url: "#",
        visible: props.user?.tipo !== "CLIENTE",
        class: "waves-effect",
        label: "Operacional",
        icon: "mdi mdi-factory",
        badgeclass: "badge badge-pill badge-success float-right",
        children: [
          {
            id: this.getIdCode(),
            url: "/admin/calculadoraMargem",
            visible: true,
            label: "Calculadora Margem",
            icon: "mdi mdi-calculator",
          },
          {
            id: this.getIdCode(),
            url: "/admin/autorizacaoINSS",
            visible: true,
            label: "Autorização INSS",
            icon: "mdi mdi-key",
          },
          // {
          //   id: this.getIdCode(),
          //   url: "/admin/autorizacaoSicoob",
          //   visible: true,
          //   label: "Autorização Sicoob",
          //   icon: "mdi mdi-key",
          // },
          {
            id: this.getIdCode(),
            url: "/admin/documentos",
            visible: true,
            label: "Documentos",
            icon: "mdi mdi-library",
          },
        ],
      },
      {
        id: this.getIdCode(),
        url: "/admin/mensagens",
        visible: props.user?.tipo == "ADMINISTRADOR",
        class: "waves-effect",
        label: "Mensagens",
        icon: "mdi mdi-mail",
        badgeclass: "badge badge-pill badge-success float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/pendencias",
        visible: true,
        class: "waves-effect",
        label: "Pendências" + (this.state.pendencias > 0 ? `(${this.state.pendencias.toString()})` : ""),
        icon: "mdi mdi-alert",
        badgeclass: "badge badge-pill badge-warning float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/retencoes",
        visible: true,
        class: "waves-effect",
        label: "Retenções",
        icon: "mdi mdi-twitter-retweet",
        badgeclass: "badge badge-pill badge-warning float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/reapresentacoes",
        visible: true,
        class: "waves-effect",
        label: "Reapresentacoes",
        icon: "mdi mdi-file-restore",
        badgeclass: "badge badge-pill badge-warning float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/solicitacoes",
        visible: ["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 || this.temPermissao('solicitacoescrud'),
        class: "waves-effect",
        label: "Solicitações",
        icon: "mdi mdi-alarm-light",
        badgeclass: "badge badge-pill badge-warning float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/configuracoes",
        class: "waves-effect",
        visible: props.user?.tipo == "ADMINISTRADOR",
        label: "Configurações",
        icon: "mdi mdi-table-settings",
        badgeclass: "badge badge-pill badge-success float-right",
      },
      {
        id: this.getIdCode(),
        url: "/admin/dashboard",
        class: "waves-effect",
        visible: props.user?.tipo == "ADMINISTRADOR",
        label: "Dashboard",
        icon: "mdi mdi-chart-line",
        badgeclass: "badge badge-pill badge-success float-right",
      },
    ];
  }

  constructor(props) {
    // console.log("Sidebar constructor: ", props);
    super(props);
    this.state = {
      matchs: "",
      selected: -1,
      pendencias: props.pendencias,
    };

    this.getMenu(props);

    // console.log("Sidebar constructor: ", this.data);
  }

  render() {
    return (
      <>
        <div style={{ top: "70px" }} className="left side-menu">
          <div className="sidebar-inner">
            <PerfectScrollbar>
              <div id="sidebar-menu">
                <ul>
                  {this.data.map((item, i) => {
                    // console.log("Sidebar constructor(Item): ", item);
                    if (item.children && item.visible) {
                      return <MenuItemWithChildren item={item} key={i} />;
                    } else if (item.visible) {
                      if (item.is_main)
                        return (
                          <li className="menu-title" key={i}>
                            {item.label}
                          </li>
                        );
                      else
                        return (
                          <li key={i}>
                            <MenuItem item={item} />
                          </li>
                        );
                    }
                  })}
                </ul>
              </div>
              {/* <Whatsappbtn phone={process.env.REACT_WHATSAPP_FONE || '5562982270001'} /> */}

            </PerfectScrollbar>
          </div>

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    pendencias: state.dados.pendencias,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
