import React from "react";
import { InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => (
  <>
    <div className="row">
      <div className="form-group col-md-12">
        <label htmlFor="banco" className="no-margin">
          Banco
        </label>
        <select
          id="banco"
          name="banco"
          value={props.data.banco}
          onChange={props.handleChange}
          style={{
            borderColor: props.warning.banco ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
          autoFocus={props.focusBanco}
        >
          {[...props.bancos].map((bancos, i) => (
            <option key={`${bancos.codigo}${i}}`} value={bancos.codigo}>
              {bancos.nome}
            </option>
          ))}
        </select>
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-3">
        <InputLabelAlert
          type="text"
          label="Agência"
          id="agencia"
          length="4"
          minLength={4}
          maxLength={4}
          warning={props.warning.agencia}
          value={props.data.agencia}
          handle={props.nums}
          focus={false}
        />
      </div>
      <div className="form-group col-md-6">
        <InputLabelAlert
          type="text"
          label="Conta"
          id="conta"
          length="25"
          warning={props.warning.conta}
          value={props.data.conta}
          handle={props.nums}
          focus={false}
        />
        {!props.interno &&
          <div
            className="valid-feedback text-info"
            style={{ display: "block", textAlign: "left" }}
          >
            * A conta informada deve ser a que você recebe sua remuneração, Anexar
            extrato bancário contendo a informação.
          </div>
        }
      </div>
      <div className="form-group col-md-3">
        <label htmlFor="t_conta" className="no-margin">
          {props.interno ? 'Tipo' : 'Tipo de Conta'}
        </label>

        <select
          className="form-control"
          defaultValue={props.data.t_conta}
          onChange={props.handleChange}
          id="t_conta"
          name="t_conta"
        >
          <option value="corrente" >Corrente</option>
          <option value="poupanca" >Poupança</option>
        </select>
        {/* <select
          label="Tipo de Conta"
          id="t_conta"
          name="t_conta"
          length="15"
          minLength={2}
          className="form-group custom-select"
          value={props.data.t_conta}
          onChange={props.handleChange}
        >
          <option value="corrente" selected={props?.data?.t_conta == "corrente"}>Corrente</option>
          <option value="poupanca" selected={props?.data?.t_conta == "poupanca"}>Poupança</option>
        </select> */}
      </div>

    </div>

    {/* <div className="row">
      
    </div> */}
  </>
);

export default Form;
