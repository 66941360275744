import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../../layout";
import {
  mCPF,
  getCookie,
  mDate,
  mTel,
  Aguarde,
} from "../../../../helpers/general";
import {
  validateCPF,
  validateDate,
  validateEmail,
} from "../../../../helpers/validation";
import { sendData, getData, bancoFornecedor } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import { setProposta, setClienteDados } from "../../../../store/actions/dados";
import { swalError, confirmSwal } from "../../../swal";
import { Modal, Spinner } from "react-bootstrap";
import * as moment from "moment";
import { orgaosList } from '../../../../helpers/Uteis'

function AutoriacaoInss({ ...props }) {
  const [cpf, setCpf] = useState();
  const [nome, setNome] = useState();
  const [ddn, setDdn] = useState();
  const [email, setEmail] = useState();
  const [telefone, setTelefone] = useState();
  const [gerada, setGerada] = useState();
  const [tipoDocumento, setTipoDocumento] = useState();
  const [numeroDoc, setNumeroDoc] = useState();
  const [dataExp, setDataExp] = useState();
  const [orgaoExp, setOrgaoExp] = useState();
  const [portabilidade, setPortabilidade] = useState('Não');

  const [warning, setWarning] = useState({});
  const [consultando, setConsultando] = useState(false);
  const [dados, setDados] = useState(null);
  const [cpfValido, setCpfValido] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const copiarLink = () => {
    let link =
      (process?.env?.REACT_FRONT_END_URL ||
        "https://simulador.incontadigital.com.br") +
      `/preenchercli/${cpf?.replace(/\D/g, "")}/inss_in100`;
    if (navigator && navigator.clipboard) navigator.clipboard.writeText(link);
  };

  // useEffect(() => {
  //   if (props.handleDisabled) props.handleDisabled(disabled)
  // }, [disabled])

  useEffect(() => {
    // console.log("AutoriacaoInss: ", props.detalhes)
  }, []);

  const CarregarDados = (cpf) => {
    let content = { cpf, tipo: "INSS_IN100" };
    setDados(null);
    sendData({
      uri: uriList("buscarAutorizacao"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setConsultando(false);

        console.log("AutoriacaoInss - res: ", res);
        if (res?.status > 200 && res.bloqueado)
          swalError({ title: res.message || "Operação não poderá ser iniciada. Favor entrar em contato com a Plataforma." })

        if (res.data && res.data.existe && res.data.valida) setDados(res.data);
        else if (res.data && res.data.nome) {
          let _exp = moment(res.data.data_exp).format("DD/MM/YYYY")
          setNome(res.data.nome);
          setDdn(moment(res.data.ddn).format("DD/MM/YYYY"));
          setEmail(res.data.email);
          setTelefone(res.data.telefone || res.data.celular);
          setCpfValido(true);
          setTipoDocumento(res.data.t_doc)
          setNumeroDoc(res.data.num_doc)
          if (_exp && _exp.toString().toLowerCase() != "invalid date") setDataExp(_exp)

          setOrgaoExp(res.data.orgao)
          setPortabilidade(res.data.portabilidade)
        } else setCpfValido(true);
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: JSON.stringify(e.message) });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  };

  const novaAutorizacao = () => {
    console.log("Nova Autorizacao");
    if (props.reRender) props.reRender();
  };

  const handleCpf = (e) => {
    setCpfValido(false);
    setWarning({ ...warning, cpf: 0 });
    let nums = /\d/;
    setDados(null);
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setCpf(cpf);
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (valid) CarregarDados(cpf);
        else setWarning({ ...warning, cpf: 1 });
      }

      return false;
    } else if (!e.currentTarget.value) setCpf(null);

    return false;
  };

  const handleChange = (e) => {
    let target = e.currentTarget;
    setDisabled(false);
    let name = target.name;
    let value = target.value;
    if (name !== "email") value = value.toUpperCase();

    if (name == "ddn") {
      let value = mDate(e.currentTarget.value);
      setDdn(value);
    } else if (name === "telefone") setTelefone(mTel(value));
    else if (name === "email") setEmail(value);
    else if (name === "nome") setNome(value);

    else if (name === "tipoDocumento") setTipoDocumento(value);
    else if (name === "numeroDoc") setNumeroDoc(value);
    else if (name === "dataExp") setDataExp(value);
    else if (name === "orgaoExp") setOrgaoExp(value);
    else if (name === "portabilidade") setPortabilidade(value);
  };

  const handleDate = (e) => {
    let nums = /[0-9]/;

    if (nums.test(e.currentTarget.value)) {
      let ddn = mDate(e.currentTarget.value);

      setDataExp(ddn)

      return false;
    }
  }

  const handleBlur = async (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let valor = target.value.toUpperCase();
    setWarning({ ...warning, [name]: 0 });
    // console.log(`name: ${name}, valor: ${valor}`)
    handleChange(e);

    if (name === "ddn" && valor) {
      if (!validateDate(valor)) setWarning({ ...warning, ["ddn"]: 1 });
    } else if (name === "email" && valor) {
      if (!validateEmail(valor)) setWarning({ ...warning, ["email"]: 1 });
    }
    else if (name === "telefone" && valor) {
      const _validar = await checarTelefone()
      console.log("[HANDLE_BLUR] - _validar: ", _validar)
    }
  };

  async function limpar() {
    setDisabled(false)
    if (props.reRender) props.reRender();
  }

  async function cancelar() {
    let content = {
      cpf,
      nome,
      ddn: ddn?.split("/").reverse().join("-"),
      telefone,
      email,
      tipo: "INSS_IN100",
    };

    sendData({
      uri: uriList("cancelarAutorizacao"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) {
          let msgErro = res.erro || res.message;
          console.log("Erro ao efetuar consulta: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          novaAutorizacao();
        }
      })
      .catch((e) => {
        swalError({ title: e.message });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  }

  async function validate() {
    const _result = await checarTelefone()
    return !_result && cpf && nome && telefone && ddn && tipoDocumento && numeroDoc && dataExp && orgaoExp
  }

  async function submit(e) {
    let content = {
      cpf,
      nome,
      ddn: ddn?.split("/").reverse().join("-"),
      telefone,
      email,
      // tipo: "INSS_IN100",
      t_doc: tipoDocumento,
      num_doc: numeroDoc,
      data_exp: dataExp?.split("/").reverse().join("-"),
      orgao: orgaoExp,
      // proposta_uuid: props.proposta,
      portabilidade
    };

    if (!(await validate())) {
      // swalError({ title: `Campo(s) obrigatório(s) não preenchido(s)!` })
      return false
    }

    setConsultando(true);

    sendData({
      uri: uriList("gerarAutorizacaoInss"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setConsultando(false);
        if (res.status && res.status > 200) {
          let msgErro = res.erro || res.message;
          console.log("Erro ao efetuar consulta: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          novaAutorizacao();
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: e.message });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  }

  async function checarTelefone() {
    console.log("[CHECAR_TELEFONE]")
    let content = {
      telefone: mTel(telefone),
      cpf: cpf
    }
    let result = false

    await sendData({
      uri: uriList("checarTelefone"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("[CHECAR_TELEFONE]-1: ", res)
        if (res.status && res.status > 200) {
          let msgErro = res.erro || res.message;
          console.log("Erro ao efetuar consulta: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        }

        if (res.data && res.data.existe) {
          swalError({ title: "Telefone já cadastrado para outro CPF!" });
          result = true
          setDisabled(true)
        }
      })
      .catch((e) => {
        swalError({ title: e.message });
        console.log("Erro ao checar telefone\n: ", e);
      });

    return result
  }

  return (
    <>
      <fieldset className="col-12" style={{ height: "100%" }}>
        <h4
          className="display-4 text-center font-weight-bold"
          style={{ marginBottom: "10px", color: "#3E6C84" }}
        >
          Autorização INSS
        </h4>

        <div className="form" style={{ overflow: "hidden" }}>
          <div className="row flex">
            <div className="form-group col-md-4">
              <InputLabelAlert
                type="tel"
                label="CPF"
                id="cpf"
                value={cpf}
                handle={handleCpf}
                autoComplete="off"
                length="14"
                focus={true}
                required
                onContextMenu={(e) => e.preventDefault()}
              />

              <InputAlert display={warning.cpf} messages={"CPF inválido."} />
            </div>
            {!dados && cpfValido && (
              <>
                <div className="form-group col-md-8">
                  <InputLabelAlert
                    type="text"
                    label="Nome"
                    id="nome"
                    value={nome}
                    handle={handleChange}
                    autoComplete="off"
                    required
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
              </>
            )}
          </div>
          {dados && (
            <div
              className="row"
              style={{
                border: "0.5px solid",
                borderColor: "lightGray",
                color: dados.dtAutorizacao ? "green" : "red",
              }}
            >
              <div className="col-md-3 col-3">
                <h6 className="no-margin">Nsu</h6>
                <h6 className="font-weight-bold m-0">{dados.nsu}</h6>
              </div>
              <div className="col-md-3 col-3">
                <h6 className="no-margin">Criado em</h6>
                <h6 className="font-weight-bold m-0">
                  {moment(dados.created_at).format("DD/MM/YYYY")}
                </h6>
              </div>
              <div className=" col-md-3 col-3">
                <h6 className="no-margin">Validade</h6>
                <h6 className="font-weight-bold m-0">
                  {moment(dados.validade).format("DD/MM/YYYY")}
                </h6>
              </div>
              <div className=" col-md-3 col-3">
                <h6 className="no-margin">Autorizada Em</h6>
                <h6 className="font-weight-bold m-0">
                  {(dados.dtAutorizacao &&
                    moment(dados.dtAutorizacao).format("DD/MM/YYYY")) ||
                    "Não Autorizada"}
                </h6>
              </div>
            </div>
          )}
          {!dados && cpfValido && (
            <>
              <div className="row flex">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Data de Nascimento"
                    id="ddn"
                    value={ddn}
                    onBlur={handleChange}
                    handle={handleBlur}
                    warning={warning.ddn}
                    length="10"
                    required
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Telefone"
                    id="telefone"
                    warning={warning.telefone}
                    value={telefone}
                    handle={handleChange}
                    onBlur={handleBlur}
                    required
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-6">
                  <InputLabelAlert
                    type="email"
                    id="email"
                    label="E-mail"
                    minLength={5}
                    value={email}
                    warning={warning.email}
                    handle={handleChange}
                    onBlur={handleBlur}
                    lowercase={true}
                    focus={false}
                  />
                </div>
              </div>
              <div className="row flex">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="text"
                    label="Tipo de Documento"
                    id="tipoDocumento"
                    length="25"
                    minLength={2}
                    list="t_doc-list"
                    inputClass="custom-select"
                    value={tipoDocumento}
                    handle={handleChange}
                    required
                    focus={false}
                  />

                  <datalist id="t_doc-list">
                    <option value="CNH">CNH</option>
                    <option value="RG">RG</option>
                    {bancoFornecedor() !== "inbursa" && <option value="Carteira Funcional">Carteira Funcional</option>}
                    {bancoFornecedor() !== "inbursa" && <option value="Passaporte">Passaporte</option>}
                  </datalist>
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="text"
                    label="Numero Doc"
                    id="numeroDoc"
                    length="50"
                    minLength={3}
                    value={numeroDoc}
                    handle={handleChange}
                    required
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Data de Expedição"
                    id="dataExp"
                    value={dataExp}
                    handle={handleDate}
                    length="10"
                    required
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="text"
                    label="Órgão Expedidor"
                    id="orgaoExp"
                    value={orgaoExp}
                    handle={handleChange}
                    inputClass="custom-select"
                    list="orgao-list"
                    length="25"
                    required
                    focus={false}
                  />

                  <datalist id="orgao-list">
                    {orgaosList?.map((item, i) => (
                      <option value={item.value} key={i}>{item.descricao}</option>
                    ))}
                  </datalist>
                </div>
              </div>
            </>
          )}
          <div className="row flex mt-3">
            <div className="form-group col-md-4">
              <button
                style={{ backgroundColor: "#3E6C84", width: "100%" }}
                onClick={submit}
                className="btn btn-md btn-info mb-1  font-weight-bold"
                disabled={
                  disabled ||
                  !cpfValido ||
                  !nome ||
                  !validateDate(ddn) ||
                  !telefone ||
                  !validateEmail(email) ||
                  consultando
                }
              >
                Gerar
              </button>
            </div>
            <div className="form-group col-md-4">
              <button
                style={{ backgroundColor: "#3E6C84", width: "100%" }}
                onClick={limpar}
                className="btn btn-md btn-info mb-1  font-weight-bold"
                disabled={!cpf}
              >
                Limpar
              </button>
            </div>
            <div className="form-group col-md-4">
              <button
                style={{ backgroundColor: "#3E6C84", width: "100%" }}
                onClick={cancelar}
                className="btn btn-md btn-info mb-1  font-weight-bold"
                disabled={!cpf}
              >
                Cancelar
              </button>
            </div>
          </div>
          {dados && (
            <div className="row flex mt-0">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-6">
                  <button
                    type="button"
                    class={`btn btn-outline-dark col-12 btn-c-gray`}
                    style={{
                      fontSize: "12px",
                      color: "black",
                      fontWeight: 'bold'
                    }}
                    onClick={copiarLink}
                  >
                    Copiar link da Autorização
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </fieldset>
      {consultando && <Aguarde />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(AutoriacaoInss);
