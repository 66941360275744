import React, { useEffect, useState } from "react";
import Dropper from "../../dropzone.js";
import { connect } from "react-redux";
import { bancoFornecedor } from "../../../helpers/auth.js";

const Form = ({ ...props }) => {
  const [t_doc, setTipoDoc] = useState("cnh");
  const [convenio, setConvenio] = useState("");

  useEffect(() => {
    console.log("Documentos(useEffect): ", props.detalhes);
    if (props.t_doc) setTipoDoc(props.t_doc.toLowerCase());

    if (props.detalhes && props.detalhes.convenio)
      setConvenio(props.detalhes.convenio.toLowerCase());
  }, [props.t_doc]);

  let legendaCBancario =
    convenio === "siape"
      ? "Tela de Autorização de Consignação"
      : "Comprovante Bancário";
  return (
    <div className={props.proposta ? "container-md" : "form"}>
      <Dropper
        name="d_pessoal"
        // title={t_doc === 'cnh' ? "CNH Digitalizada ou Foto" : "Documento Pessoal com foto"}
        title={"Documento Pessoal com foto"}
        data={props.data.d_pessoal}
        change={props.change}
        del={props.del}
        disabled={props.disabled}
        accept={"application/pdf,image/*,text/plain"}
        aguardar={props.aguardar}
        warning={props.warning.d_pessoal}
        // field={t_doc === 'cnh' ? "CNH Digitalizada ou Foto" : "Documento Pessoal com foto"}
        field={"Documento Pessoal com foto"}
      />

      <Dropper
        name="d_pessoalv"
        title="Verso Documento Pessoal"
        data={props.data.d_pessoalv}
        change={props.change}
        del={props.del}
        tipo="d_pessoalv"
        disabled={props.disabled}
        descricao={"VERSO DO DOCUMENTO PESSOAL"}
        accept={"application/pdf,image/*,text/plain"}
        aguardar={props.aguardar}
        warning={props.warning.d_pessoalv}
        field="Verso Documento Pessoal"
        marginTop={"10px"}
      />

      {["SICOOB", "ORIGINAL"].indexOf(props.detalhes.tipo_convenio) >= 0 && (
        <Dropper
          name="c_endereco"
          title="Comprovante de Endereço"
          data={props.data.c_endereco}
          change={props.change}
          del={props.del}
          disabled={props.disabled}
          descricao={"COMPROVANTE DE ENDERECO"}
          accept={"application/pdf,image/*,text/plain"}
          aguardar={props.aguardar}
          field="Comprovante de Endereço"
          marginTop={"10px"}
        />
      )}

      {!props.docPessoal &&
        ["ORIGINAL"].indexOf(props.detalhes.tipo_convenio) < 0 && (
          <Dropper
            name="contracheque"
            title="Último Contracheque"
            data={props.data.contracheque}
            change={props.change}
            del={props.del}
            disabled={props.disabled}
            accept={"application/pdf,image/*,text/plain"}
            aguardar={props.aguardar}
            warning={props.warning.contracheque}
            field="Contracheque"
            marginTop={"10px"}
          />
        )}

      {!props.docPessoal &&
        ["ORIGINAL"].indexOf(props.detalhes.tipo_convenio) < 0 && (
          <Dropper
            name="c_bancario"
            title={legendaCBancario}
            data={props.data.c_bancario}
            change={props.change}
            del={props.del}
            disabled={props.disabled}
            accept={"application/pdf,image/*,text/plain"}
            aguardar={props.aguardar}
            warning={props.warning.c_bancario}
            field={legendaCBancario}
            marginTop={"10px"}
          />
        )}

      {!props.docPessoal &&
        props.portabilidade &&
        ["ORIGINAL"].indexOf(props.detalhes.tipo_convenio) < 0 && (
          <Dropper
            name="ded"
            title={"DED - Demonstrativo de Evolução da Dívida"}
            data={props.data.ded}
            change={props.change}
            del={props.del}
            disabled={props.disabled}
            accept={"application/pdf,image/*,text/plain"}
            aguardar={props.aguardar}
            warning={props.warning.ded}
            field={"ded"}
            marginTop={"10px"}
          />
        )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    t_doc: state.dados.t_doc,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(Form);
