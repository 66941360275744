import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import Status from '../views/status';
import { parseComplexJson, sendData } from '../helpers/auth';
import { width } from '../helpers/general';
import { uriList } from '../helpers/config';
import { connect } from 'react-redux'
import { setProposta } from '../store/actions/dados'

function ProgressStatus(props) {
	const [data, setData] = useState('');
	const [isUser, setIsUser] = useState(true);
	const [simulador, setSimulador] = useState(false);

	//-- Retorna da para a tela do simulador
	function AbreSimulador() {
		if (!props.portabilidade) {
			props.alterarProposta("limpar")
			setSimulador(true)
		}
	}

	useEffect(() => {
		let _uuid = props.proposta
		console.log("Acompanhando status da proposta: ", _uuid)
		console.log("status.useEffect(Usuário): ", props.user)
		if (_uuid) {
			sendData({ uri: uriList('acompanharProposta'), content: { uuid: _uuid }, method: 'POST' })
				.then(res => {
					if (res?.posicao?.toLowerCase() === 'simulacao' && !props.preencherCli && !props.user) {
						console.log("status - OK(1)")
						AbreSimulador()
					}
					else {
						console.log("status - OK(2)")
						setIsUser(true);
						setData(previous => ({ ...previous, ...res }))
					}
					console.log("status - data: ", res)
				})
				.catch(e => {
					setIsUser(false);
					setData(false);
				});
		}
		else
			AbreSimulador()

	}, []);

	return (
		<>
			{simulador && <Redirect to="./" />}

			<div className="container-md">
				{props.enviando ?
					<h2 className="text-center" style={{ opacity: "60%" }}>Enviando proposta, aguarde...</h2>
					:
					!data
						? <h5
							className="lead text-info">
							{isUser ?
								<div className="text-center my-3">
									{props.portabilidade ? <h1>Proposta enviada!</h1> : <span>Carregando a sua Proposta!</span>}

								</div> :
								(
									<div
										className="text-center my-3">
										<span
											className="d-block lead">Não existe proposta cadastrada!</span>

										<Link
											to="/"
											className="btn btn-sm btn-info col-12 col-md-4 mt-3">Nova Proposta</Link>
									</div>
								)}
						</h5>
						: <Status
							preencherCli={props.preencherCli}
							portabilidade={props.portabilidade}
							toggleStep={props.toggleStep}
							title={width() === 'mobile' ? 'Acompanhamento' : "Acompanhamento de Proposta"}
							data={data} />

				}
			</div>
		</>
	)
}

function mapStateToProps(state) {
	return {
		proposta: state.dados.proposta,
		detalhes: state.dados.detalhes,
		cliente: state.dados.cliente,
		user: state.dados.user
	}
}

function mapActionToProps(dispatch) {
	return {
		alterarProposta(novaProposta) {
			dispatch(setProposta(novaProposta))
		},
	}
}


export default connect(
	mapStateToProps,
	mapActionToProps
)(ProgressStatus)

//-- Troca de branch(teste)