import React, { useState, useEffect } from "react";
import { formatCurrency, width } from "../../helpers/general";
import { sendData, getData } from "../../helpers/auth";
import { Buttons } from '../../components/layout';
import { uriList } from '../../helpers/config';
import { connect } from 'react-redux'
import { Modal, Spinner } from 'react-bootstrap'
import * as moment from 'moment'

function SaldoDevedor({ ...props }) {
    const [saldoOk, setSaldoOk] = useState(false)
    const [consultando, setConsultando] = useState(true)
    const [descricao, setDescricao] = useState("Saldo devedor não disponível")
    const [erro, setErro] = useState(false)

    useEffect(() => {
        consultarSolicitacaoSado()
    }, [])

    function enviarParaAssinatura() {

    }

    function voltar() {
        if (saldoOk && props.onAccept)
            props.onAccept()

        if (props.onCloseEv)
            props.onCloseEv()
    }

    async function consultarSolicitacaoSado() {
        setErro(false)
        await sendData({
            uri: uriList('consultarSolicitacaoSaldo'),
            content: { uuid: props.proposta, multSelect: props?.detalhes?.multSelect }, method: 'POST'
        })
            .then(res => {
                setConsultando(false)
                console.log("saldoDevedor.consultarSolicitacaoSaldo(Solicitação de Saldo: ", res)
                // console.log("Solicitação de Saldo: ", res?.message?.situacao)
                if (res?.status && parseInt(res.status) > 200) {
                    setErro(true)
                    setDescricao(res?.message || "Saldo devedor não disponível")
                    throw new Error();
                }
                setDescricao(res?.message?.situacao?.descricao)
                if (res?.message?.situacao?.id == "27")
                    setSaldoOk(true)
            })
            .catch(e => {
                setConsultando(false)
                // alert('Erro ao consultar Solicitacao de Saldo')
            });

    }

    function onClick(e) {
    }

    return (
        <>
            <Modal size={'lg'} scrollable show={true} animation={false} backdrop="static" centered>
                <Modal.Body className="maxWidth750"
                    style={{ width: '100%', justifyContent: "center", textAlign: "center", color: "#66b2cc" }}
                >
                    {consultando && <div style={{ textAlign: "center", width: "100%" }}><br /><h3 style={{ opacity: "60%", color: "black" }}>Aguarde, consultando saldo devedor...</h3></div>}
                    {!consultando && <div style={{ textAlign: "center", width: "100%", color: erro ? "red" : "" }}><br /><h2 >{`${erro ? "Erro: " : ""}` + descricao}</h2></div>}
                    <Buttons
                        first="Voltar"
                        second='Resolicitar Saldo'
                        goBack={voltar}
                        // submit={enviarParaAssinatura}
                        disabledSecond={true}
                        hideSecond={false} />
                </Modal.Body>
            </Modal>
        </>
    );
}

const format = value => {
    value = formatCurrency(value);

    return value;
};

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        user: state.dados.user,
        alterarProposta: state.dados.alterarProposta,
        detalhes: state.dados.detalhes
    }
}


export default connect(
    mapStateToProps,
)(SaldoDevedor)