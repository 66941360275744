import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/contact";

import { Buttons } from "../../components/layout";
import { mTel, mDDD, getCookie, HORARIOLIST } from "../../helpers/general";
import { validateEmail } from "../../helpers/validation";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";

import { connect } from "react-redux";

function Contact({ ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({
    celular: "",
    telefone: "",
    horario: "",
    email: "",
    obs: "",
    ip: getCookie({ label: "ip" }),
    id: props.cliente,
    proposta_uuid: props.proposta,
  });

  const [showAlert, setAlert] = useState({
    celular: 0,
    telefone: 0,
    horario: 0,
    email: 0,
    obs: 0,
  });

  const [alternate, setAlternate] = useState({
    email: 0,
  });

  const [alternateMessage, setAlternateMessage] = useState({
    email: "",
  });

  function handleChange(e, contactData) {
    let target = e.currentTarget;
    let value = contactData || target.value;
    if (data && target) {
      if (target.name === "email")
        setData({ ...data, [target.name]: value.toLowerCase() });
      else if (target.name === "telefone" || target.name === "celular") {
        let tel = mTel(target.value);
        setData({ ...data, [target.name]: tel });
      } else setData({ ...data, [target.name]: value });

      if (
        data[target.name] &&
        data[target.name].length === 0 &&
        target.value === "" &&
        target.name !== "email"
      ) {
        setAlert({ ...showAlert, [target.name]: 1 });
      } else {
        clearAlert(e);
      }
    }
  }

  function handleEmail(e) {
    let target = e.currentTarget;

    handleChange(e);
    setAlternate({ ...alternate, email: 0 });

    if (
      target.value &&
      !validateEmail(target.value, props?.user, props?.clienteDados) &&
      target.value.length >= 1
    ) {
      setAlternate({ ...alternate, email: 1 });
      setAlternateMessage({
        ...alternateMessage,
        email: "Email inválido!",
      });
    }
  }

  function handleDDD(e) {
    let target = e.currentTarget;
    let nums = /[0-9]/;
    if (!target.value || nums.test(target.value)) {
      let ddd = mDDD(target.value);
      handleChange(e, ddd);

      return false;
    }

    if (
      data &&
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      handleChange(e);

      return false;
    }

    return false;
  }

  function handleTelefone(e) {
    let target = e.currentTarget;
    let nums = /[0-9]/;

    if (!target.value || nums.test(target.value)) {
      let tel = mTel(target.value);
      handleChange(e, tel);

      return false;
    }

    if (
      data &&
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      let tel = mTel(target.value);
      handleChange(e, tel);

      return false;
    }

    return false;
  }

  function clearAlert(e) {
    let target = e.currentTarget;

    setAlert({ ...showAlert, [target.name]: 0 });
  }

  function submit(callback) {
    let invalid = isInvalid(data);

    if (!invalid) {
      let newData = Object.assign(data);

      for (let n in newData) {
        if (newData[n] && n !== "ip" && n !== "id")
          newData[n] = newData[n].toUpperCase();
      }

      send(
        {
          uri: uriList("pessoas"),
          content: { ...newData, proposta_uuid: props.proposta, },
          obj: newData,
          key: "contato",
        },
        props.toggleStep,
        false,
        false,
        callback
      );

      for (let d in data) {
        if (d === "email") props.clienteDados[d] = data[d].toLowerCase();
        else props.clienteDados[d] = data[d];
      }

      return false;
    } else if (callback) callback(false);

    setAlert(invalid);
  }

  function handleEnter(e) {
    // handleChange(e, e.currentTarget.value.replace(/\D/g, ''))
  }

  useEffect(() => {
    for (let d in data)
      if (props.clienteDados[d])
        setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
    setLoaded(true);
  }, []);

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>

      {loaded ? (
        <>
          <div className="form">
            <Form
              data={data}
              changeData={handleChange}
              changeTel={handleTelefone}
              // changeDDD={handleDDD}
              warning={showAlert}
              onEnter={handleEnter}
              onClick={handleEnter}
              alternate={alternate}
              horarioList={HORARIOLIST}
              alternateMessage={alternateMessage}
              email={handleEmail}
            />
          </div>

          <Buttons
            goBack={() => goBack(data, "contato", props.toggleStep)}
            submit={submit}
          />
        </>
      ) : (
        ""
      )}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
    preencherCli: state.dados.preencherCli,
    clienteDados: state.dados.clienteDados,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Contact);
