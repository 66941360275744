import React, { useState, useEffect } from 'react';

import SimulatorLayout from '../components/layout';
import Result from '../views/simulator/result';
import { connect } from 'react-redux'
import { setUser, setAlterarProposta, setCliente, setProposta } from '../store/actions/dados'
import Status from './status'
import Calc from '../components/form/admin/CalculadoraMargem'

import Data from '../views/simulator/data';
import Address from '../views/simulator/address';
import Contact from '../views/simulator/contact';
import Complement from '../views/simulator/complement';
import Bank from '../views/simulator/bank';
import Documents from '../views/simulator/documents';
import FinanceData from '../views/simulator/financedata';
import { sendData } from "../helpers/auth";
import { uriList } from '../helpers/config';

function CalculadoraMargem({ ...props }) {
  const [data, setData] = useState({})
  const [result, setResult] = useState({})
  const [enviando, setEnviando] = useState(false)
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 8
  });

  useEffect(() => {
    props.setProposta("limpar")
    props.setAlterarProposta(false, props.detalhes)
    props.alterarCliente(null)
  }, []);

  const reRender = () => {
    // console.log("ReRender")
    setStep({ ...step, cur: -1 });
    setStep({ ...step, cur: 0 });
  }

  const toggleStep = async (n, dados, result) => {
    console.log("calculadoraMargem - toggleStep: ", n)
    setData(dados)
    setResult(result)
    if (!n || n === undefined)
      n = 1
    let _next = step.cur + n
    if (_next >= 0 && _next <= step.maxStep)
      setStep({ ...step, cur: _next });

    //-- Enviando proposta para o cliente 
    if (step.cur >= step.maxStep) {
      setEnviando(true)
      await sendData({ uri: uriList('enviarParaAssinatura'), content: { proposta_uuid: props.proposta, sms: true }, method: 'POST' })
        .then(res => {
          setEnviando(false)
          if (res.status && parseInt(res.status) > 200) throw new Error();
        })
        .catch(e => {
          setEnviando(false)
        });

      if (props.buscarPropostas) {
        props.buscarPropostas()
      }

      return setStep({ ...step, cur: 0 });
    }
  }

  const RenderScreen = ({ ...props }) => ({
    '0': <Calc toggleStep={toggleStep} reRender={reRender} data={data} />,

    '1': <Result
      title='Simulação'
      toggleStep={toggleStep}
      result={data}
      data={result}
      admin={true} />,

    '2': <Data
      title="Dados Pessoais"
      toggleStep={toggleStep}
      admin={true} />,

    '3': <Address
      title='Endereço'
      toggleStep={toggleStep}
      admin={true} />,

    '4': <Contact
      title='Contato'
      toggleStep={toggleStep}
      admin={true} />,

    '5': <Bank
      title="Dados Bancários"
      toggleStep={toggleStep}
      admin={true} />,

    '6': <Complement
      title='Complemento'
      toggleStep={toggleStep}
      admin={true} />,

    '7': <Documents
      title="Documentos"
      toggleStep={toggleStep}
      admin={true} />,

    '8': <Status
      toggleStep={toggleStep}
      admin={true}
      enviando={enviando} />,

  })[props.step] || (<><h1>404!</h1><h4>Esse formulário: {props.step}não existe! </h4></>);

  return (
    <>
      {step.cur >= step.maxStep ?
        <RenderScreen step={step.cur} /> :
        <SimulatorLayout admin={true}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>}
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes
  }
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta))
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser))
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar))
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente))
    },
  }
}

export default connect(
  mapStateToProps,
  mapActionToProps
)(CalculadoraMargem)
