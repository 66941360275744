import React, { useState } from "react";
import ModalContent from "../../components/layout/ModalContent";
import Logo from "../../assets/logo.png";
import SeloSSL from "../../assets/selo.png";
import { connect } from "react-redux";
import { width } from "../../helpers/general";

const Footer = (props) => {
  const [saibaMais, setSaibaMais] = useState(false);
  const telefones =
    props.detalhes?.tipo_convenio == "SICOOB"
      ? "Ouvidoria SICOOB: 0800 725 0996 | Deficientes auditivos e de fala: 0800 940 0458"
      : props.detalhes?.tipo_convenio == "ORIGINAL"
        ? `Renegociação de Crédito Pessoal 0800 878 9755 Demais
  Informações e SAC 0800 744 0744`
        : "SAC BRB 0800.648.6161 | SAC-PNE 0800.648.6162 | Ouvidoria BRB 0800.642.1105";

  // : props.detalhes?.tipo_convenio == "OUTROS"
  // ? "SAC BRB 0800.648.6161 | SAC-PNE 0800.648.6162 | Ouvidoria BRB 0800.642.1105"
  // : "";
  const site =
    props.detalhes.tipo_convenio == "SICOOB"
      ? "http://www.sicoobexecutivo.com.br/ns/"
      : props.detalhes.tipo_convenio == "ORIGINAL"
        ? "https://www.original.com.br"
        : "https://novo.brb.com.br/";
  const paraSaber = `A InConta Crédito Digital, pessoa jurídica que não constitui-se como uma Instituição Financeira, trata-se de um Correspondente no País, nos moldes entabulados pelas Resoluções 3.954/2011 e 4.935/2021 do Banco Central do Brasil. Sediada à Avenida Tocantins, nº375, segundo andar, Setor Central, Goiânia-GO, CEP 75015-010, CNPJ 41.561.368/0001-06. A InConta Crédito Digital está autorizada a intermediar propostas de produtos das instituições financeiras e cooperativas parceiras em seus canais de venda, cumprindo rigorosamente a política de crédito da Instituição Correspondida, sendo atribuição privativa desta as condições operacionais de cada produto, incluindo taxas de juros e prazos negociais.`;

  console.log("Footer.props: ", props.detalhes);

  function toggleCloseModal() {
    console.log("footer.toggleClose");
    setSaibaMais(false);
  }

  return (
    <>
      {!props.user ? (
        <div
          style={{
            fontSize: "8px",
            bottom: "1px",
            width: "100%",
            height: props.hideContact ? "80vh" : "",
            align: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {!props.hideContact && (
            <>
              <div>
                <a
                  href={site}
                  target="_blank"
                  style={{ color: "#3E6C84", fontSize: "12px" }}
                >
                  {telefones}
                </a>
              </div>

              <div>
                <a
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "darkBlue",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setSaibaMais(true);
                  }}
                >
                  Saiba Mais
                </a>
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      {!props.user && saibaMais && (
        <ModalContent title="Para Saber" onCloseEv={toggleCloseModal}>
          {paraSaber}
        </ModalContent>
      )}

      {/* <div
        className={width() == "mobile" ? "" : "d-flex justify-content-between"}
        style={{
          textAlign: "center",
          marginTop: props.user ? "0px" : "10px",
          marginBottom: props.user ? "5px" : "15px",
        }}
      >
        <div className="col-12 d-flex align-items-center justify-content-center">
          <img
            src={Logo}
            title="Inconta"
            alt="Inconta Digital"
            style={{ width: width() == "mobile" ? "200px" : "250px" }}
          />
        </div>
        <div className={width() == "mobile" ? "col-12" : ""}>
          <img
            src="https://www.d4sign.com.br/selo3-d4s.png"
            width={width() == "mobile" ? "80px;" : "200px;"}
            title="D4Sign"
            alt="D4Sign"
          />
        </div>
      </div> */}
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    cliente: state.dados.cliente,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(Footer);
