import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import '../../../sass/login.scss';
import Logo from '../../../images/logo-inteira.png';
// import Logo from '../../../images/secure_login.svg'; 

import { saveCookie, width } from '../../../helpers/general';
import { sendData, setItemCrypt, getItemCrypt, removeItemCrypt, bancoFornecedor } from "../../../helpers/auth";
import { validateEmail } from '../../../helpers/validation';
import { uriList, TOKEN_BANK } from '../../../helpers/config';
import swal from 'sweetalert';
import { connect } from "react-redux";

const BankSelection = ({ selectBank }) => {
    const banks = [
        { name: "brb", icon: "../../../images/logo-brb.png" },
        { name: "inbursa", icon: "../../../images/logo-inbursa.png" },
        { name: "cef", icon: "../../../images/logo-cef.png" },
    ];

    return (
        <div className="bank-selection" >
            <h3 className="text-center" style={{ color: " #3E6C84", marginBottom: "15px" }}>Escolha o Banco</h3>
            <div className="bank-icons" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "0px", marginBottom: "15px" }}>
                {banks.map((bank) => (
                    <button
                        key={bank.name}
                        className="bank-icon"
                        onClick={() => selectBank(bank.name)}
                        style={{
                            padding: "5px",
                            margin: "3px",
                            border: "none",
                            background: "transparent",
                            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                            backgroundColor: "white",
                            transition: 'transform 0.2s'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    >
                        <img
                            src={bank.icon}
                            alt={bank.name}
                            style={{
                                width: '130px',
                                height: '110px',
                                display: 'block',
                                margin: '0 auto'
                            }}
                        />
                    </button>
                ))}
            </div>
        </div>


        // <div className="bank-selection">
        //     <h3 className="text-center">Escolha o Banco</h3>
        //     <div className="bank-icons" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
        //         {banks.map((bank) => (
        //             <div key={bank.name} className="bank-icon" onClick={() => selectBank(bank.name)} style={{ padding: "10px", margin: "15px", }}>
        //                 <img src={bank.icon} alt={bank.name} style={{ width: '140px', height: '120px' }} />
        //             </div>
        //         ))}
        //     </div>
        // </div>
    );
};

const Form = ({ ...props }) => {
    const [novaSenha, setNovaSenha] = useState(false)
    const [urlUserLogo, setUrlUserLogo] = useState(props.user?.url_logo)
    const [captcha, setCaptcha] = useState("");
    const [multiBancos,] = useState(process.env.REACT_APP_MULTBANCOS == "sim")
    // const [selectedBank, setSelectedBank] = useState(multiBancos ? getItemCrypt(TOKEN_BANK) : 'brb');
    const [selectedBank, setSelectedBank] = useState(bancoFornecedor());

    function onClickBtn(e) {
        console.log("login.onClickBtn")
        if (captcha)
            props.submit(e, captcha)
    }

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(2): ", props.MfaAuthentication)
    }, [props.MfaAuthentication])

    useEffect(() => {
        console.log("[LOGIN-VIEW] useEffect(1) ")
        console.log("[LOGIN-BANK] useEffect(bank): ", getItemCrypt(TOKEN_BANK))
        if (!multiBancos)
            setItemCrypt(TOKEN_BANK, process.env.REACT_APP_BANCO_FORNECEDOR || 'brb');

    }, [])

    function onClickBtnMfa(e) {
        console.log("login.onClickBtn")
        if (props.data["tokenMFA"])
            props.submitmfa(e)
    }

    function handleCaptcha(value) {
        // console.log("login.handleCaptcha: ", value)
        setCaptcha(value)
    }

    const handleNovaSenha = (e) => {
        console.log("login.handleNovaSenha")
        e.preventDefault();
        if (props.data.email && validateEmail(props.data.email)) {
            console.log("login.handleNovaSenha(1)")
            sendData({ uri: uriList('recuperarSenha'), content: { email: props.data.email }, method: 'POST' })
                .then(res => {
                    console.log("login.handleNovaSenha(OK)")
                    if (res.status && parseInt(res.status) > 200) throw new Error();
                    swal({
                        title: "Alteração de Senha",
                        text: "Acesse o email informado e siga as instruções...",
                        timer: 7000
                    })
                        .then(p => {
                            setNovaSenha(false)
                        });

                })
                .catch(e => {
                    console.log("login.handleNovaSenha(Erro)")
                    console.log(e)
                    // alert("Erro ao tentar recuperar Senha")
                });
        }
    }

    const NovaSenha = () => {
        return (
            <>
                <div className="form-group">
                    <label
                        style={{ fontSize: '1em' }}
                        htmlFor="novaSenha">Email</label>
                    <input
                        type="email"
                        className={`form-control ${props.warning?.email ? 'is-invalid' : ''}`}
                        id="email"
                        name="email"
                        required
                        value={props.data?.email}
                        onChange={props.handle}
                        autoFocus={true}
                        placeholder="Ex.: exemplo@exemplo.com"
                    />
                </div>

                <div className="text-right">
                    <button
                        className="btn btn-success w-md waves-effect waves-light"
                        onClick={handleNovaSenha}
                        style={{ cursor: "pointer" }}
                    >
                        Nova Senha
                    </button>
                </div>

            </>
        )
    }

    const selectBank = (bank) => {
        setSelectedBank(bank);
        setItemCrypt(TOKEN_BANK, bank);
    };

    const clearSelectedBanck = () => {
        setSelectedBank(multiBancos ? null : 'brb');
        removeItemCrypt(TOKEN_BANK);
    }

    const clearSection = () => {
        setNovaSenha(false);
        setSelectedBank(multiBancos ? null : 'brb');
        removeItemCrypt(TOKEN_BANK);
        localStorage.clear();
        if (props.clearMFA)
            props.clearMFA();
    }
    // if (!selectedBank) {
    //     return <BankSelection selectBank={selectBank} />;
    // }

    return (
        <>
            {/* {width() !== 'mobile' && <div className="accountbg"></div>} */}
            < div className="accountbg" ></div>
            <div
                style={{
                    maxHeight: width() === 'mobile' ? (window.innerHeight + 30) + 'px' : novaSenha ? '15em' : '46em',
                    backgroundColor: width() === 'mobile' ? '#ffffff' : '',
                    overflowY: width() === 'mobile' ? 'auto' : 'hidden',
                    margin: "auto"
                }}
                // className={`col-md-5 mx-auto ${width() !== 'mobile' && 'card'}`}>
                className={`col-md-5 mx-auto card`}>
                <h3 className="text-center m-0">
                    <Link
                        to="/"
                        className="logo logo-admin"
                        title="Sistema"
                    >
                        <img src={Logo}
                            style={{
                                width: width() !== "mobile" ? "200px" : "100px"
                            }}

                            alt="Login" />
                    </Link>
                </h3>

                <br />
                {
                    <>
                        {novaSenha && <NovaSenha />}
                        {!selectedBank && multiBancos && <BankSelection selectBank={selectBank} />}

                        {!novaSenha && !props.MfaAuthentication && selectedBank &&
                            <>
                                <div className="form-group">
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control ${props.warning.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        required
                                        value={props.data.email}
                                        onChange={props.handle}
                                        autoFocus={true}
                                        placeholder="Ex.: exemplo@exemplo.com"
                                    />

                                    <div className="invalid-feedback">
                                        Email é inválido ou não existe!
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="password">Senha</label>
                                    <input
                                        type="password"
                                        className={`form-control ${props.warning.password ? 'is-invalid' : ''}`}
                                        id="password"
                                        name="password"
                                        required
                                        placeholder="Digite sua Senha"
                                        value={props.data.password}
                                        onChange={props.handle}
                                    />

                                    <div className="invalid-feedback">
                                        A senha é obrigatória!
                                    </div>
                                </div>

                                <div className="form-group row" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="col-sm-6">
                                        <div className="custom-control mt-2 custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customCheck1"
                                                checked={props.data.connected}
                                                onChange={props.checked}
                                                name="connected"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customCheck1"
                                            >
                                                Manter Conectado
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0 row">
                                    <div className="col-12 m-t-20">
                                        <span onClick={() => setNovaSenha(true)} style={{ cursor: "pointer" }}><i className="mdi mdi-lock"></i> Esqueceu sua Senha?</span>
                                    </div>
                                </div>

                                <div className="form-group" >
                                    <div style={{ display: "flex", justifyContent: "center", }}>
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            size={width() == "mobile" ? "compact" : "normal"}
                                            onChange={handleCaptcha} />
                                    </div>
                                </div>

                                <div className="text-right" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                                    {process.env.multiBancos &&
                                        <button
                                            className="btn btn-secondary w-md waves-effect waves-light "
                                            onClick={clearSelectedBanck}
                                            style={{ cursor: "pointer", width: "20%", marginRight: "20px", display: "flex" }}
                                        >
                                            <i className={"mdi mdi-step-backward"} ></i>
                                            Banco
                                        </button>
                                    }
                                    <button
                                        className="btn btn-success w-md waves-effect waves-light"
                                        onClick={onClickBtn}
                                        disabled={!captcha}
                                        style={{ cursor: "pointer", width: "40%" }}
                                    >
                                        Acessar
                                    </button>
                                </div>


                            </>
                        }
                        {props.MfaAuthentication &&
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "25px" }}>
                                    <label
                                        style={{ fontSize: '1em' }}
                                        htmlFor="tokenMFA">Codigo MFA</label>
                                    <input
                                        type="text"
                                        className={`form-control`}
                                        id="tokenMFA"
                                        name="tokenMFA"
                                        required
                                        value={props.data["tokenMFA"]}
                                        autoFocus={true}
                                        onChange={props.handle}
                                        placeholder="Código Autenticação MFA"
                                    />
                                </div>
                                <div
                                    className="text-right"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px", // espaço entre os botões
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <button
                                        className="btn btn-success w-md waves-effect waves-light"
                                        onClick={onClickBtnMfa}
                                        disabled={!captcha}
                                        style={{
                                            cursor: "pointer",
                                            maxWidth: "45%",
                                            flex: 1, // para garantir que ambos ocupem o mesmo espaço
                                        }}
                                    >
                                        Acessar
                                    </button>
                                    <button
                                        className="btn w-md waves-effect waves-light"
                                        onClick={clearSection}
                                        title="Limpa a sessão e volta para a tela de Login"
                                        style={{
                                            cursor: "pointer",
                                            maxWidth: "45%",
                                            flex: 1, // para garantir que ambos ocupem o mesmo espaço
                                            backgroundColor: "#fad095", // Cor bege pastel
                                            borderColor: "#e6d2b0", // Bordas para combinar com o tom
                                            color: "#5a4a3a", // Texto contrastante
                                        }}
                                    >
                                        Limpar Sessão
                                    </button>
                                </div>


                                {/* <div className="text-right" style={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "20px" }}>
                                    <button
                                        className="btn btn-success w-md waves-effect waves-light"
                                        onClick={onClickBtnMfa}
                                        disabled={!captcha}
                                        style={{ cursor: "pointer", width: "40%" }}
                                    >
                                        Acessar
                                    </button>
                                    <button
                                        className="btn btn-warning w-md waves-effect waves-light ml-2"
                                        onClick={clearSection}
                                        title={"Limpa a sessão e volta para a tela de Login"}
                                        style={{ cursor: "pointer", width: "30%", }}
                                    >
                                        Limpar Sessão
                                    </button>
                                </div> */}
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
};

function mapStateToProps(state) {
    return {
        user: state.dados.user,
    };
}


export default connect(mapStateToProps,)(Form);


