import React from "react";
import PropTypes from "prop-types";
import { width } from "./../helpers/general";
import swal from "sweetalert";

/**
 * Wrapper component for sweetalert plugin
 */
const Swal = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    // pass swal reference so is possible to chain popups
    swal(props.options).then((p) => props.callback(p, swal, id));
  };

  const { id, callback, ...rest } = props;

  return (
    <div {...rest} onClick={handleClick}>
      {props.children}
    </div>
  );
};

Swal.propType = {
  /** swal options object */
  options: PropTypes.object.isRequired,
  /** callback function for swal response */
  callback: PropTypes.func,
};

Swal.defaultProps = {
  callback: () => { },
};

export const confirmSwal = (props) => {
  const _defaultButtons = {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  }

  const alterButtons = {
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "",
      closeModal: true,
    },
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  }

  const buttons = props.changeOrderButtons ? { ...alterButtons } : { ..._defaultButtons };
  const swalOptions = {
    title: "Confirma?",
    text: props.text || "",
    icon: "warning",
    className: width() != "mobile" ? "swal-confirm" : "",
    buttons
  };

  const { id } = props;
  let _options = swalOptions;
  if (props.title) _options = { ..._options, title: props.title };
  if (typeof _options.title === "object")
    _options.title = JSON.stringify(_options.title);

  swal(_options)
    .then((p) => {
      if (props.callback) props.callback(p, swal, id);
    })
    .catch((err) => {
      if (props.catchCallback) props.catchCallback(err, swal, id);
    });
};

export const swalWarning = (props) => {
  const swalOptions = {
    title: "Atenção",
    icon: "warning",
    className: width() != "mobile" ? "swal-warning" : "",
    buttons: {
      cancel: {
        text: "Fechar",
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
  };

  const { id } = props;
  let _options = swalOptions;
  if (props.title) _options = { ..._options, title: props.title };
  if (typeof _options.title === "object")
    _options.title = JSON.stringify(_options.title);

  swal(_options).then((p) => {
    if (props.callback) props.callback(p, swal, id);
  });
};

export const swalError = async (props) => {
  const swalOptions = {
    title: "Ocorreu um erro",
    text: "",
    icon: "error",
    className: width() != "mobile" ? "swal-error" : "",

    buttons: {
      cancel: {
        text: "Fechar",
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
  };

  const { id } = props;
  let _options = swalOptions;
  if (props.title || props) {
    _options.title = props.title || props;

    if (typeof _options.title === "object")
      _options.title = JSON.stringify(_options.title);
    console.log("SwalError: ", _options);
  }
  if (props.text) _options.text = props.text;

  if (typeof _options.text === "object")
    _options.text = JSON.stringify(_options.text);

  if (props.promise)
    return new Promise((resolve, reject) => {
      swal(_options).then((p) => {
        if (props.callback) props.callback(p, swal, id);
        resolve(p);
      });
    })
  else
    swal(_options).then((p) => {
      if (props.callback) props.callback(p, swal, id);
    });
};

export const swalMessage = async (props) => {
  const swalOptions = {
    title: "Mensagem",
    text: "",
    icon: "info",
    className: width() != "mobile" ? "swal-warning" : "",

    buttons: {
      cancel: {
        text: "Fechar",
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
  };

  const { id } = props;
  let _options = swalOptions;
  if (props.title || props) {
    _options.title = (props.title || props);

    if (typeof _options.title === "object")
      _options.title = JSON.stringify(_options.title);

    // _options.title = props.title || props;
    console.log("SwalMessage: ", _options);
  }
  if (props.text) _options.text = typeof props.text == "object" ? JSON.stringify(props.text) : props.text;

  if (props.promise)
    return new Promise((resolve, reject) => {
      swal(_options).then((p) => {
        if (props.callback) props.callback(p, swal, id);
        resolve(p);
      });
    })
  else
    swal(_options).then((p) => {
      if (props.callback) props.callback(p, swal, id);
    });
};

export default Swal;
