import React, { useState, useEffect, useCallback } from "react";

import Form from "../../components/form/admin/Tabelas/tabelas";

import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import {
  DefaultOrder,
  sortByString,
  sortByNumber,
  compareDate,
} from "../../helpers/general";

function Tabelas({ ...props }) {
  const [tabelas, setTabelas] = useState([]);
  const [tabelasC, setTabelasC] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [total, setTotal] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [filterOptions, setFilterOptions] = useState(false);
  const [dateVisible, setDateVisible] = useState("todos");
  const [modal, setModal] = useState(false);
  const [clickedTr, setClickedTr] = useState("");
  const [ascDesc, setAscDesc] = useState(true);
  const [tabela_filter, setTabelaFilter] = useState("");
  const [ocultarinativas, setOcultarInativas] = useState("Nao");

  const handleOption = (e) => {
    let label = e.currentTarget.dataset.label || e.currentTarget.name;
    let value = e.currentTarget.value;
    let newDetailed;
    console.log("Tabelas.handleOption(1): ", label, value);
    if (label == "ocultarinativas") {
      newDetailed = tabelasC.filter((proposta) => {
        let result =
          value == "Nao" ||
          (proposta["status"].toLowerCase() !== "inativo");
        return result;
      });

      setOcultarInativas(value);
      setTabelas(newDetailed);
      setActualPage(0);
    }
    else if (value !== "todos" && value !== "") {
      newDetailed = tabelasC.filter((proposta) => {
        if (ocultarinativas == "Sim" && proposta["status"].toLowerCase() == "inativo") return false;
        if ((label = "tabela_filter")) {
          let result =
            (proposta["nome"] &&
              proposta["nome"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["convenio"] &&
              proposta["convenio"].toLowerCase().indexOf(value.toLowerCase()) >=
              0) ||
            (proposta["status"] &&
              proposta["status"].toLowerCase() == value.toLowerCase());

          return result;
        } else return proposta[label] === value;
      });

      setTabelas(newDetailed);
      setActualPage(0);
    } else {
      setTabelas(tabelasC);
      setActualPage(0);
    }
  };

  const handleTabela = (e) => {
    let value = e.currentTarget.value;
    setTabelaFilter(value);
    handleOption(e);
  };

  const handlePage = (e) => {
    setActualPage(e.currentTarget.value);
  };

  const createFilter = (obj) => {
    let newObj = {
      nome: [],
      convenio_id: [],
    };

    for (let o of obj) {
      for (let i in o) {
        if (newObj[i]) {
          newObj[i].push(o[i]);
        }
      }
    }

    for (let i in newObj) {
      newObj[i] = [...new Set(newObj[i])];
    }

    setFilterOptions(newObj);
  };

  const _convenios = useCallback(() => {
    getData({ uri: uriList("convenios") })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        setConvenios(res);
      })
      .catch((e) => {
        return false;
      });
  });

  const _tabelas = async () => {
    console.log("Tabelas._tabelas(1)");
    await sendData({
      uri: uriList("listarTabelas"),
      content: { todos: true },
      method: "POST",
    })
      .then((res) => {
        console.log("Tabelas._tabelas(ok): ", res);
        if (res.status && res.status > 200) throw new Error();
        DefaultOrder(
          "tabelas",
          res.map((tabelas) => tabelas),
          setTabelas
        );
        createFilter(res.map((tabelas) => tabelas));
        setTabelasC(res);
      })
      .catch((e) => {
        console.log("Tabelas._tabelas(error): ", e);
        return false;
      });
  };

  useEffect(() => {
    _tabelas();
    _convenios();
  }, []);

  const sortArrObj = (key, type) => {
    let arr = [...tabelas];
    let asc = !ascDesc;

    arr = arr.sort((a, b) => {
      if (type === "string") {
        if (asc) {
          setAscDesc(asc);
          return sortByString(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByString(b, a, key, type);
        }
      } else if (type === "numeric") {
        if (asc) {
          setAscDesc(asc);
          return sortByNumber(a, b, key, type);
        } else {
          setAscDesc(asc);
          return sortByNumber(b, a, key, type);
        }
      } else {
        if (asc) {
          setAscDesc(asc);
          return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
        } else {
          setAscDesc(asc);
          return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
        }
      }
    });

    setTabelas(arr);
  };

  return (
    <>
      <Form
        filterOptions={filterOptions}
        handleOption={handleOption}
        setDateVisible={setDateVisible}
        tabela_filter={tabela_filter}
        handleTabela={handleTabela}
        setModal={setModal}
        setTabelas={setTabelas}
        tabelas={tabelas}
        setClickedTr={setClickedTr}
        sortArrObj={sortArrObj}
        clickedTr={clickedTr}
        convenios={convenios}
        actualPage={actualPage}
        handlePage={handlePage}
        dateVisible={dateVisible}
        total={total}
        callback={_tabelas}
        context={props.context}
        ocultarinativas={ocultarinativas}
        setOcultarInativas={setOcultarInativas}
      />

      {modal}
    </>
  );
}

export default Tabelas;
