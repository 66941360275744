import React, { useState, useEffect } from "react";

import { InputLabelAlert, InputAlert } from "../../../layout";
import {
  mCPF,
  getCookie,
  mDate,
  mTel,
  width,
  Aguarde
} from "../../../../helpers/general";
import {
  validateCPF,
  validateDate,
  validateEmail,
} from "../../../../helpers/validation";
import { sendData, getData } from "../../../../helpers/auth";
import { uriList } from "../../../../helpers/config";
import { connect } from "react-redux";
import {
  setProposta,
  setClienteDados,
  setCliente,
} from "../../../../store/actions/dados";
import { swalError, confirmSwal } from "../../../swal";
import { Modal, Spinner } from "react-bootstrap";
import * as moment from "moment";

function AutorizacaoSicoob({ ...props }) {
  const [cpf, setCpf] = useState();
  const [nome, setNome] = useState();
  const [ddn, setDdn] = useState();
  const [email, setEmail] = useState();
  const [celular, setCelular] = useState();

  const [warning, setWarning] = useState({});
  const [consultando, setConsultando] = useState(false);
  const [dados, setDados] = useState(null);
  const [cpfValido, setCpfValido] = useState(false);

  useEffect(() => {
    // console.log("AutorizacaoSicoob: ", props.detalhes)
  }, []);

  const CarregarDados = (cpf) => {
    let content = { cpf, tipo: "SICOOB" };
    setDados(null);
    setConsultando(true);
    sendData({
      uri: uriList("buscarAutorizacao"),
      content,
      signal: null,
      method: "POST",
    })
      .then(async (res) => {
        console.log("AutorizacaoSicoob - res: ", res);
        setConsultando(false);
        // if (res.status && res.status > 200 && (!res.data || !res.data.tipo)) {
        //   let erro = "Erro ao consultar autorização";
        //   console.log("Erro ao efetuar consulta: ", res.message);

        //   swalError({ title: erro });
        //   // throw new Error();
        // } else
        if (res.data && res.data.existe) {
          setDados(res.data);
        } else {
          setNome(res.data?.nome);
          setDdn(moment(res.data?.ddn).format("DD/MM/YYYY"));
          setCelular(res.data?.celular);
          setEmail(res.data?.email);

          setCpfValido(true);
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: JSON.stringify(e.message) });
        console.log("Erro de Simulação de Refin\n: ", e);
      });
  };

  // const Aguarde = () => {
  //   return <Aguarde />
  //   // return (
  //   //   <Modal
  //   //     size={"md"}
  //   //     scrollable
  //   //     show={true}
  //   //     animation={false}
  //   //     backdrop="static"
  //   //     centered
  //   //   >
  //   //     <Modal.Body
  //   //       className="maxWidth750"
  //   //       style={{
  //   //         width: "100%",
  //   //         justifyContent: "center",
  //   //         textAlign: "center",
  //   //       }}
  //   //     >
  //   //       <Spinner animation="border" />
  //   //       {"  "}
  //   //       Aguarde enquanto o processo é finalizado...
  //   //     </Modal.Body>
  //   //   </Modal>
  //   // );
  // };

  const handleCpf = (e) => {
    setCpfValido(false);
    setWarning({ ...warning, cpf: 0 });
    let nums = /\d/;
    setDados(null);
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setCpf(cpf);
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (valid) CarregarDados(cpf);
        else setWarning({ ...warning, cpf: 1 });
      }

      return false;
    } else if (!e.currentTarget.value) setCpf(null);

    return false;
  };

  const handleChange = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;
    if (name !== "email") value = value.toUpperCase();

    if (name == "ddn") setDdn(mDate(e.currentTarget.value));
    else if (name === "celular") setCelular(mTel(value));
    else if (name === "email") setEmail(value);
    else if (name === "nome") setNome(value);

    // console.log(name + ":", value)
  };

  const handleBlur = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let valor = target.value.toUpperCase();
    setWarning({ ...warning, [name]: 0 });
    // console.log(`name: ${name}, valor: ${valor}`)
    handleChange(e);

    if (name === "ddn" && valor) {
      if (!validateDate(valor)) setWarning({ ...warning, ["ddn"]: 1 });
    } else if (name === "email" && valor) {
      if (!validateEmail(valor)) setWarning({ ...warning, ["email"]: 1 });
    }
  };

  async function enviarEmail() {
    async function callback() {
      sendData({
        uri: uriList("emailAnaliseRiscoSicoob"),
        content: { cpf: dados.cpf, tipo: "SICOOB" },
        method: "POST",
      })
        .then(async (res) => {
          console.log("emailAnaliseRiscoSicoob - res: ", res);
          limpar();
        })
        .catch((e) => {
          swalError({ title: JSON.stringify(e.message) });
          console.log("Erro ao enviar email: ", e);
        });
    }
    confirmSwal({ callback, title: "Confirma o envio do Email" });
  }

  async function limpar() {
    if (props.reRender) props.reRender();
  }

  async function submit() {
    let data = ddn.toString().split("/").reverse().join("-");
    let content = {
      cpf,
      nome,
      ddn: data,
      celular,
      email,
      tipo: "SICOOB",
      // proposta_uuid: props.proposta
    };
    console.log("AutorizacaoSicoob - submit: ", data);

    setConsultando(true);

    sendData({
      uri: uriList("gerarAutorizacao"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setConsultando(false);
        console.log("AutorizacaoSicoob - res: ", res);
        if (res.status && res.status > 200) {
          let msgErro = res.erro || res.message;
          console.log("Erro ao gerar Autorizacao: ", msgErro);

          swalError({ title: msgErro });
          // throw new Error();
        } else if (props) {
          console.log("AutorizacaoSicoob.index(gerar): ", res.data);
          props.alterarCliente(res.data?.cliente.id);
          props.setClienteDados(res.data.cliente);
          if (props.toogleStep) props.toogleStep(1, res.data.autorizacao);
          // novaAutorizacao()
        }
      })
      .catch((e) => {
        setConsultando(false);
        swalError({ title: e.message });
        console.log("Erro ao gerar autorização Sicoob: ", e);
      });
  }

  return (
    <>
      <fieldset className="col-12" style={{ height: "100%" }}>
        <h4
          className="display-4 text-center font-weight-bold"
          style={{ marginBottom: "10px", color: "#3E6C84" }}
        >
          Autorização Sicoob
        </h4>

        <div className="form" style={{ overflow: "hidden" }}>
          <div className="row flex">
            <div className="form-group col-md-4">
              <InputLabelAlert
                type="tel"
                label="CPF"
                id="cpf"
                value={cpf}
                handle={handleCpf}
                autoComplete="off"
                length="14"
                focus={true}
                onContextMenu={(e) => e.preventDefault()}
              />

              <InputAlert display={warning.cpf} messages={"CPF inválido."} />
            </div>
            {!dados && cpfValido && (
              <div className="form-group col-md-8">
                <InputLabelAlert
                  type="text"
                  label="Nome"
                  id="nome"
                  value={nome}
                  handle={handleChange}
                  // disabled={true}
                  autoComplete="off"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            )}
          </div>
          {dados && (
            <div
              className={width() != "mobile" ? "row" : ""}
              style={{
                border: "0.5px solid",
                borderColor: "lightGray",
                color: dados.dtAutorizacao ? "green" : "red",
              }}
            >
              <div
                className={
                  width() != "mobile"
                    ? "col-md-3 col-3"
                    : "row d-flex flex-column ml-2"
                }
              >
                <h6 className="no-margin">Número</h6>
                <h6 className="font-weight-bold m-0">{dados.nsu}</h6>
              </div>
              <div
                className={
                  width() != "mobile"
                    ? "col-md-3 col-3"
                    : "row d-flex flex-column ml-2"
                }
              >
                <h6 className="no-margin">Criado em</h6>
                <h6 className="font-weight-bold m-0">
                  {moment(dados.created_at).format("DD/MM/YYYY")}
                </h6>
              </div>
              <div
                className={
                  width() != "mobile"
                    ? "col-md-3 col-3"
                    : "row d-flex flex-column ml-2 mb-3"
                }
              >
                <h6 className="no-margin">Autorizada Em</h6>
                <h6 className="font-weight-bold m-0">
                  {(dados.dtAutorizacao &&
                    moment(dados.dtAutorizacao).format("DD/MM/YYYY")) ||
                    "Não Autorizada"}
                </h6>
              </div>
            </div>
          )}
          {!dados && cpfValido && (
            <div className="row flex">
              <div className="form-group col-md-3">
                <InputLabelAlert
                  type="tel"
                  label="Data de Nascimento"
                  id="ddn"
                  value={ddn}
                  onBlur={handleChange}
                  handle={handleBlur}
                  // disabled={true}
                  warning={warning.ddn}
                  length="10"
                  focus={false}
                />
              </div>
              <div className="form-group col-md-3">
                <InputLabelAlert
                  type="tel"
                  label="Celular"
                  id="celular"
                  warning={warning.celular}
                  value={celular}
                  handle={handleChange}
                  // disabled={true}
                  onBlur={handleBlur}
                  focus={false}
                />
              </div>
              <div className="form-group col-md-6">
                <InputLabelAlert
                  type="email"
                  id="email"
                  label="E-mail"
                  minLength={5}
                  value={email}
                  warning={warning.email}
                  handle={handleChange}
                  // disabled={true}
                  onBlur={handleBlur}
                  lowercase={true}
                  focus={false}
                />
              </div>
            </div>
          )}
          <div className="row flex mt-3">
            {cpfValido && (
              <div className="form-group col-md-4">
                <button
                  style={{ backgroundColor: "#3E6C84", width: "100%" }}
                  onClick={submit}
                  className="btn btn-md btn-info mb-1  font-weight-bold"
                  disabled={!cpfValido}
                >
                  Gerar
                </button>
              </div>
            )}
            <div className="form-group col-md-4">
              <button
                style={{ backgroundColor: "#3E6C84", width: "100%" }}
                onClick={limpar}
                className="btn btn-md btn-info mb-1  font-weight-bold"
                disabled={!cpf}
              >
                Limpar
              </button>
            </div>
            {dados?.dtAutorizacao && (
              <div className="form-group col-md-4">
                <button
                  style={{ backgroundColor: "#3E6C84", width: "100%" }}
                  onClick={enviarEmail}
                  className="btn btn-md btn-info mb-1  font-weight-bold"
                  disabled={!dados}
                >
                  Enviar Email
                </button>
              </div>
            )}
          </div>
        </div>
      </fieldset>
      {consultando && <Aguarde />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    setClienteDados(novoCliente) {
      dispatch(setClienteDados(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(AutorizacaoSicoob);
