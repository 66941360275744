import React, { useState, useEffect } from "react";

import SimulatorLayout from "../components/layout";
import { connect } from "react-redux";
import {
  setUser,
  setAlterarProposta,
  setCliente,
  setProposta,
} from "../store/actions/dados";

import Data from "../views/simulator/data";
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
import Complement from "../views/simulator/complement";
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";
import FinanceData from "../views/simulator/financedata";
import { bancoFornecedor, sendData } from "../helpers/auth";
import { uriList } from "../helpers/config";
import { width } from '../helpers/general';
import Status from "./status";

function Portabilidade({ ...props }) {
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 7,
  });
  const [enviando, setEnviando] = useState(false);

  useEffect(() => {
    console.log("Portabilidade - props: ", props.detalhes);
    if (!props.alterar) _iniciar();
    else {
      console.log("Portabilidade - detalhes: ", props.detalhes.posicao);
      if (["simulacao"].indexOf(props.detalhes.posicao.toLowerCase()) >= 0)
        setStep({ ...step, cur: 1 });
      else setStep({ ...step, cur: step.maxStep });
    }

  }, []);


  function _iniciar() {
    props.setProposta("limpar");
    props.setAlterarProposta(false, props.detalhes);
    props.alterarCliente(null);
  }

  const toggleStep = async (n, dados, result) => {
    console.log("Portabilidade - toggleStep: ", n);
    // if (dados) setData(dados);
    // if (result) setResult(result);
    if (!n || n === undefined) n = 1;
    let _next = step.cur + n;
    if (_next >= 0 && _next <= step.maxStep) setStep({ ...step, cur: _next });

    //-- Enviando proposta para o cliente
    if (step.cur >= step.maxStep && !props.detalhes.port_autorizada && props.detalhes.tipo_convenio != "INSS") {
      if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0) return setStep({ ...step, cur: 0 });

      console.log("Solicitando autorização portabilidade!");
      setEnviando(true);
      sendData({
        uri: uriList("solicitarAutorizacaoPortabilidade"),
        content: { uuid: props.proposta, sms: true },
        method: "POST",
      })
        .then((res) => {
          setEnviando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();

          _iniciar();

          // if (props.buscarPropostas)
          //   props.buscarPropostas()
        })
        .catch((e) => {
          setEnviando(false);
        });
    }
    else if (step.cur >= step.maxStep) {
      _iniciar();
    }
    //-- autorizando portabilidade
    else if (step.cur >= step.maxStep - 1 && props.detalhes.tipo_convenio == "INSS") {
      // if (["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) >= 0) return setStep({ ...step, cur: 0 });

      console.log("Solicitando autorização portabilidade!");
      setEnviando(true);
      sendData({
        // uri: uriList("autorizarPortabilidade"),
        uri: uriList("analiseAutomatica"),
        content: { uuid: props.proposta, proposta_uuid: props.proposta, sms: true, enviarParaAssinatura: true },
        method: "POST",
      })
        .then((res) => {
          setEnviando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
        })
        .catch((e) => {
          // _iniciar();
          setEnviando(false);
        });
    }
  };

  const RenderScreen = ({ ...props }) =>
  ({
    0: (
      <FinanceData
        title="Portabilidade"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    1: (
      <Data
        title="Dados Pessoais"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    2: (
      <Address
        title="Endereço"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    3: (
      <Contact
        title="Contato"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    4: (
      <Bank
        title="Dados Bancários"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    5: (
      <Complement
        title="Complemento"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    6: (
      <Documents
        title="Documentos"
        toggleStep={toggleStep}
        admin={true}
        portabilidade={true}
      />
    ),

    7: (
      <Status
        preencherCli={false}
        data={props.detalhes}
        portabilidade={true}
        toggleStep={toggleStep}
        enviando={enviando}
      />
    ),
  }[props.step] || (
      <>
        {/* <h1>404!</h1>
        <h4>Esse formulário: {props.step}não existe! </h4> */}
      </>
    ));


  return (
    <>
      {step.cur >= step.maxStep ? (
        <RenderScreen step={step.cur} />
      ) : (
        <SimulatorLayout admin={true}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Portabilidade);
