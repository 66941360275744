import React, { useState } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal from "../../swal";
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import {
  mCEP,
  mCPF,
  mTel,
  mDDD,
} from "../../../helpers/general";

import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";

let statuses = [
  { value: "ATIVO", nome: "Ativo" },
  { value: "INATIVO", nome: "Inativo" },
];

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const dataIni = {
  id: 0,
  nome: "",
  cnpj: "",
  email: ""
}
const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    empresas,
    actualPage,
    handlePage,
    callback,
    sortArrObj,
    setClickedTr,
    clickedTr,
  } = props;

  const [show, setShow] = useState(false);
  const [data, setData] = useState(dataIni)
  const [method, setMethod] = useState("POST");

  const handleClose = () => setShow(false);

  const handleEdit = async (id) => {
    console.log("empresas.handleEdit(0)")
    getData({ uri: uriList("empresas") + "/" + id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        setData(res);
        setMethod("PUT");

        setShow(true);
        console.log("empresas.handleEdit(ok): ", res)
      })
      .catch((e) => {
        console.log("empresas.handleEdit(error)")
        console.log(e)
        return false;
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm) {
      await sendData({
        uri: uriList("empresas") + "/" + id,
        content: {},
        method: "DELETE",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          callback();
        })
        .catch((e) => {
          alert("Erro ao excluir empresa.");
        });
    }
  };

  const handlePost = async () => {
    const { id, ..._data } = data
    let dObj = _data;

    console.log("Gravando empresa...");
    console.log(dObj);
    if (method === "PUT") dObj["id"] = id;

    await sendData({
      uri: uriList("empresas") + (method === "PUT" ? "/" + id : ""),
      content: dObj,
      method,
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200)
          throw new Error(res.message[0].message);
        callback();
        setShow(false);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const handleNew = () => {
    setData(dataIni);
    setMethod("POST");

    setShow(true);
  };

  // function handleCEP(e) {
  //   let nums = /\d/;
  //   let target = e.currentTarget;

  //   if (nums.test(target.value)) {
  //     let cep = mCEP(target.value);
  //     target.name === "cep" ? handleChange(e, cep) : handleChange(e);
  //     target.name === "cep" && setGetCep(1);

  //     return false;
  //   }

  //   if (data[target.name].length === 1 && target.value === "") {
  //     handleChange(e);

  //     return false;
  //   }

  //   return false;
  // }

  // const handleChange = (e, userData) => {
  //   let target = e.currentTarget;
  //   let name = target.name;
  //   let value = userData || target.value;
  //   // console.log(`Pessoas - handleChange(${name}): `, value);
  //   // if (name !== "email" && value) value = value.toUpperCase();
  //   if (target.name === "email") {
  //     setData({ ...data, [target.name]: value.toLowerCase() });
  //     console.log("Pessoas.handleChange(target): ", target.value)
  //     if (
  //       target.value &&
  //       !validateEmail(target.value, props.user, data)
  //     ) {
  //       console.log("Pessoas.handleChange(email inválido): ", target.value)
  //       setWarning({ ...warning, email: 1 });
  //     }
  //   } else if (target.name === "dependentes") {
  //     setData({ ...data, [target.name]: value.replace(/\D/g, "") });
  //   } else if (
  //     ["ddn", "data_exp", "data_situacao", "data_admissao"].indexOf(name) >= 0
  //   ) {
  //     setData({ ...data, [name]: mDate(e.currentTarget.value) });
  //   } else if (
  //     [
  //       "referencia1_telefone",
  //       "referencia2_telefone",
  //       "telefone_rh",
  //       "telefone_comercial",
  //       "celular",
  //       "telefone",
  //     ].indexOf(target.name) >= 0
  //   )
  //     setData({ ...data, [name]: mTel(value) });
  //   else if (["salario", "patrimonio"].indexOf(name) >= 0)
  //     setData({ ...data, [name]: formatCurrency(value) });
  //   else if (name == "cep") setData({ ...data, [name]: mCEP(target.value) });
  //   else if (["conjuge_cpf", "cpf"].indexOf(name) >= 0) {
  //     let cpf = mCPF(e.currentTarget.value);
  //     if (cpf.length <= 14) {
  //       setData({ ...data, [target.name]: cpf });
  //       setWarning({
  //         ...warning,
  //         [target.name]: cpf.length === 14 && !validateCPF(cpf) ? 1 : 0,
  //       });
  //     }
  //   } else if (name === "uf")
  //     setData({
  //       ...data,
  //       [name]: value,
  //       cidade: getFirstCity(value),
  //     });
  //   else if (name === "ufn")
  //     setData({
  //       ...data,
  //       [name]: value,
  //       cidaden: getFirstCity(value),
  //     });
  //   else setData({ ...data, [name]: value });

  //   setWarning({ ...warning, [target.name]: 0 })
  //   // console.log(`***Pessoas - handleChange(${name}): `, value);
  // };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Empresas</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <div>
            <div className="row">
              <div className="col-md-4">
                <label className="m-0 font-90">Cnpj</label>
                <input
                  id="cnpj"
                  name="cnpj"
                  type="text"
                  className="form-control"
                  autoFocus
                  value={data.cnpj}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-8">
                <label className="m-0 font-90">Nome</label>
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.nome}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <label className="m-0 font-90">Cpf</label>
                <input
                  id="cpf"
                  name="cpf"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.cpf}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-8">
                <label className="m-0 font-90">Email</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.email}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-8">
                <label className="m-0 font-90">Endereço</label>
                <input
                  id="endereco"
                  name="endereco"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.endereco}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <label className="m-0 font-90">Cidade</label>
                <input
                  id="cidade"
                  name="cidade"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.cidade}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-5">
                <label className="m-0 font-90">Bairro</label>
                <input
                  id="bairro"
                  name="bairro"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.bairro}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3">
                <label className="m-0 font-90">Cep</label>
                <input
                  id="cep"
                  name="cep"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.cep}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <label className="m-0 font-90">Telefone</label>
                <input
                  id="telefone"
                  name="telefone"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.telefone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-4">
                <label className="m-0 font-90">Código</label>
                <input
                  id="codigo"
                  name="codigo"
                  type="text"
                  className="form-control"
                  // autoFocus
                  value={data.codigo}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-5">
                <label className="m-0 font-90">Identificador</label>
                <input
                  id="uuid"
                  name="uuid"
                  type="text"
                  className="form-control"
                  disabled
                  value={data.uuid}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3">
                <label className="m-0 font-90">Status</label>
                <select
                  className="form-control"
                  defaultValue={data.status}
                  onChange={handleChange}
                >
                  {statuses.map((item, key) => {
                    return (
                      <option key={key} value={item.value}>
                        {item.nome}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Novo empresa"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <div className="row ">
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Empresa</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="nome"
          />
        </div>
      </div>
      <div className="row py-2">
        <div className={`col-md-12`}>
          <InputLabelAlert
            className="m-0 font-90"
            type="text"
            label="Empresa/CNPJ"
            id="empresa_filter"
            cifrao={false}
            value={props.empresa_filter}
            handle={props.handleempresa}
            focus={true}
          />
        </div>
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Cnpj
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Empresa
            </th>

            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              email
            </th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {empresas.length > 0 ? (
            empresas.map(
              (empresa, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${clickedTr === empresa.id ? "bg-secondary text-light" : ""
                      }`}
                    onClick={() => {
                      setClickedTr(empresa.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(empresa.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(empresa.id);
                    }}
                  >
                    <td>{i + 1}</td>
                    <td id={i} style={{ minWidth: "100px", maxWidth: "100px" }}>{empresa.cnpj}</td>
                    <td id={i} style={{ minWidth: "450px", maxWidth: "450px" }}>{empresa.nome}</td>
                    <td id={i} style={{ minWidth: "250px", maxWidth: "250px" }}>{empresa.email}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-warning"
                        title="Altera um registro"
                        onClick={(e) => handleEdit(empresa.id)}
                      >
                        <i className="mdi mdi-file-edit-outline"></i>
                      </button>
                      <Swal
                        options={swalOptions}
                        id={empresa.id}
                        title="Exclusão de Registro"
                        callback={handleDelete}
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                      >
                        <i className="mdi mdi-delete"></i>
                      </Swal>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de empresas: {empresas && empresas.length}
            </td>

            <td colSpan="7"></td>
          </tr>
        </tfoot>
      </table>
      <Pagination
        detailed={empresas}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

export default Form;
