import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal from "../../swal";
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";
import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";
import { formatFloat, formatCurrency, mDate } from "../../../helpers/general";
import * as moment from "moment";

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    coeficientes,
    actualPage,
    handlePage,
    sortArrObj,
    callback,
    setClickedTr,
    clickedTr,
    setCoeficientes,
  } = props;

  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const [method, setMethod] = useState("POST");
  const [gravando, setGravando] = useState(false);

  const handleClose = () => setShow(false);
  const handleEdit = async (id) => {
    getData({ uri: uriList("coeficientes") + "/" + id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();
        let _data = data;
        for (var campo in res) {
          if (campo == "coeficiente") _data[campo] = formatFloat(res[campo], 6);
          else if (
            res[campo] &&
            (campo === "dt_inicial" || campo === "dt_final")
          ) {
            // let _date = new Date(res[campo]).toLocaleDateString()
            let _date = moment(res[campo]).format("DD/MM/YYYY");
            if (_date && _date.toLowerCase() !== "invalid date")
              _data[campo] = _date;
          } else if (
            campo != "prazo" &&
            campo != "tabela_id" &&
            campo != "prazofinal"
          )
            _data[campo] = formatCurrency(formatFloat(res[campo]));
          else _data[campo] = res[campo];
        }

        setData(_data);

        setMethod("PUT");
        setShow(true);
      })
      .catch((e) => {
        console.log("erro: ", e);
        return false;
      });
  };

  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm) {
      await sendData({
        uri: uriList("coeficientes") + "/" + id,
        content: {},
        method: "DELETE",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          callback();
        })
        .catch((e) => {
          alert("Erro ao excluir Coeficiente.");
        });
    }
  };

  const handlePost = async () => {
    setGravando(true);
    let dObj = new FormData();

    dObj.append("tabela_id", data.tabela_id);
    dObj.append("coeficiente", formatFloat(data.coeficiente, 6));
    dObj.append("prazo", data.prazo);
    dObj.append("prazofinal", data.prazofinal);
    if (data.dt_inicial && data.dt_inicial > 0)
      dObj.append("dt_inicial", data.dt_inicial);
    if (data.dt_final && data.dt_final > 0)
      dObj.append("dt_final", data.dt_final);
    dObj.append("juros", formatFloat(data.jurosm));
    dObj.append("jurosm", formatFloat(data.jurosm));
    dObj.append("jurosa", formatFloat(data.jurosa));
    dObj.append("iof", formatFloat(data.iof));
    dObj.append("tac", formatFloat(data.tac));
    dObj.append("custo_efetivo", formatFloat(data.custo_efetivom));
    dObj.append("custo_efetivom", formatFloat(data.custo_efetivom));
    dObj.append("custo_efetivoa", formatFloat(data.custo_efetivoa));
    dObj.append("id", parseInt(data.id));
    console.log(dObj);

    await sendMultiPartData({
      uri:
        uriList("coeficientes") +
        (method === "PUT" ? "/" + parseInt(data.id) : ""),
      content: dObj,
      method,
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200)
          throw new Error(res.message[0].message);
        callback();
        setShow(false);
        setGravando(false);
      })
      .catch((e) => {
        setGravando(false);
        alert(e);
      });
  };

  const handleNew = () => {
    const dataIni = {
      id: 0,
      tabela_id: 0,
      coeficiente: 0,
      prazo: 0,
      prazofinal: 0,
      dt_inicial: null,
      dt_final: null,
      juros: 0,
      jurosm: 0,
      jurosa: 0,
      iof: 0,
      tac: 0,
      custo_efetivo: 0,
      custo_efetivom: 0,
      custo_efetivoa: 0,
    };

    setData(dataIni);

    setMethod("POST");
    setShow(true);
  };

  const handleChange = (e, userData) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "dt_inicial" || name == "dt_final") {
      let value = mDate(e.currentTarget.value);
      setData({ ...data, [name]: value });
    } else setData({ ...data, [name]: value });
  };

  const handleBlur = (e, userData) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = userData || target.value;

    if (name == "coeficiente") value = formatFloat(value, 6);
    else if (name == "dt_inicial" || name == "dt_final")
      value = mDate(e.currentTarget.value);
    else if (name != "prazo" && name != "tabela_id" && name != "prazofinal")
      value = formatCurrency(formatFloat(value));

    setData({ ...data, [name]: value });
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Cadastro de Coeficientes</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          {gravando ? (
            <div>Gravando registro...</div>
          ) : (
            <div className="form" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="form-group col-md-6">
                  <InputLabelAlert
                    type="numeric"
                    label="Coeficiente"
                    id="coeficiente"
                    cifrao={false}
                    value={data.coeficiente}
                    step="0.10"
                    handle={handleChange}
                    onChange={data.coeficiente}
                    onBlur={handleBlur}
                    focus={true}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Prazo Inicial"
                    id="prazo"
                    cifrao={false}
                    value={data.prazo}
                    step="0.10"
                    handle={handleChange}
                    onChange={data.prazo}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Prazo Final"
                    id="prazofinal"
                    cifrao={false}
                    value={data.prazofinal}
                    step="0.10"
                    handle={handleChange}
                    onChange={data.prazofinal}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Data Inicial"
                    id="dt_inicial"
                    cifrao={false}
                    value={data.dt_inicial}
                    handle={handleChange}
                    onChange={data.dt_inicial}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="tel"
                    label="Data Final"
                    id="dt_final"
                    cifrao={false}
                    value={data.dt_final}
                    handle={handleChange}
                    onChange={data.dt_final}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Juros"
                    id="jurosm"
                    cifrao={false}
                    value={data.jurosm}
                    handle={handleChange}
                    onChange={data.jurosm}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Juro Anual"
                    id="jurosa"
                    cifrao={false}
                    value={data.jurosa}
                    handle={handleChange}
                    onChange={data.jurosa}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="IOF"
                    id="iof"
                    cifrao={false}
                    value={data.iof}
                    handle={handleChange}
                    onChange={data.iof}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Tarifa"
                    id="tac"
                    cifrao={false}
                    value={data.tac}
                    handle={handleChange}
                    onChange={data.tac}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Custo Efetivo"
                    id="custo_efetivom"
                    cifrao={false}
                    value={data.custo_efetivom}
                    handle={handleChange}
                    onChange={data.custo_efetivom}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
                <div className="form-group col-md-3">
                  <InputLabelAlert
                    type="numeric"
                    label="Custo Efetivo Anual"
                    id="custo_efetivoa"
                    cifrao={false}
                    value={data.custo_efetivoa}
                    handle={handleChange}
                    onChange={data.custo_efetivoa}
                    onBlur={handleBlur}
                    focus={false}
                  />
                </div>
              </div>

              <label className="m-0 font-90">Tabela</label>
              <select
                className="form-control"
                defaultValue={data.tabela_id}
                onChange={(e) =>
                  setData({ ...data, ["tabela_id"]: e.target.value })
                }
              >
                {props.tabelas &&
                  props.tabelas.map((item, key) => {
                    if (!data.tabela_id) {
                      setData({ ...data, ["tabela_id"]: item.id });
                    }
                    return (
                      <option key={key} value={item.id}>
                        {item.nome}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Novo Coeficiente"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <div className="row py-2">
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Tabela</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="tabela_id"
          />
        </div>
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Convênio</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="convenio_id"
          />
        </div>
        <div className={`col-md-8`}>
          <InputLabelAlert
            className="m-0 font-90"
            type="text"
            label="Tabela/Convênio"
            id="coeficiente_filter"
            cifrao={false}
            value={props.coeficiente_filter}
            handle={props.handleCoeficiente}
            focus={true}
          />
        </div>
      </div>

      {/* <div className='table-responsive'> */}
      <table className="table table-bordered table-striped">
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Tabela
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("tabela_id", "string")}
            >
              Convênio
            </th>
            <th
              scope="col"
              className="font-80 text-right"
              onClick={() => sortArrObj("coeficiente", "numeric")}
            >
              Coeficiente
            </th>
            <th
              scope="col"
              className="font-80 text-right"
              onClick={() => sortArrObj("prazo", "numeric")}
            >
              Prazo
            </th>

            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {coeficientes.length > 0 ? (
            coeficientes.map(
              (Coeficiente, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${clickedTr === Coeficiente.id
                        ? "bg-secondary text-light"
                        : ""
                      }`}
                    onClick={() => {
                      setClickedTr(Coeficiente.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(Coeficiente.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(Coeficiente.id);
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{Coeficiente.tabela}</td>
                    <td>{Coeficiente.convenio}</td>
                    <td
                      className="font-80 text-right"
                      style={{ maxWidth: "50px" }}
                    >
                      {Coeficiente.coeficiente}
                    </td>
                    <td
                      className="font-80 text-right"
                      style={{ maxWidth: "50px" }}
                    >
                      {Coeficiente.prazo}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-warning"
                        title="Altera um registro"
                        onClick={(e) => handleEdit(Coeficiente.id)}
                      >
                        <i className="mdi mdi-file-edit-outline"></i>
                      </button>
                      <Swal
                        options={swalOptions}
                        id={Coeficiente.id}
                        title="Exclusão de Registro"
                        callback={handleDelete}
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                      >
                        <i className="mdi mdi-delete"></i>
                      </Swal>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de Coeficientes: {coeficientes && coeficientes.length}
            </td>

            <td colSpan="7"></td>
          </tr>
        </tfoot>
      </table>
      <Pagination
        detailed={coeficientes}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

export default Form;
