import React, { useState } from 'react';
import { Button, Input, Row, Col, Card } from 'reactstrap';
import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';  // Importação do Bootstrap CSS

function App(props) {
    const [show, setShow] = useState(false);


    return (
        <div>
            <Modal show={true} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Exemplo de Modal com React-Bootstrap</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input type="select" name="select" id="exampleSelect">
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                    </Input>

                    <Row>
                        <Col sm="6">Column 1</Col>
                        <Col sm="6">Column 2</Col>
                    </Row>

                    <Card>
                        <div className="p-3">
                            This is a card, similar to Paper in Material-UI.
                        </div>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="secondary" onClick={props.handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
