import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/address";

import { Buttons } from "../../components/layout";
import { cidades } from "../../helpers/cidades";
import { pureGetData, getData } from "../../helpers/auth";
import { obterDominio } from "../../helpers/original";
import { mCEP, getCookie, LOGRADOUROS } from "../../helpers/general";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";

import { connect } from "react-redux";

function Address({ ...props }) {
  let estados = cidades();
  let logradouros = LOGRADOUROS.replace(/\s/g, "").split(",");

  const [tipos_endereco, setTiposEndereco] = useState(
    obterDominio("TIPO_ENDERECO")
  );

  const [data, setData] = useState({
    cep: "",
    t_log: "rua",
    logradouro: "",
    complemento: "",
    numero: "",
    bairro: "",
    uf: "AC",
    cidade: "",
    tipo_endereco: tipos_endereco[0].CODIGO,
    ip: getCookie({ label: "ip" }),
    id: props.cliente,
    proposta_uuid: props.proposta,
  });
  const [loading, setLoading] = useState(false);

  const [showAlert, setAlert] = useState({
    cep: 0,
    t_log: 0,
    logradouro: 0,
    complemento: 0,
    tipo_endereco: 0,
    numero: 0,
    bairro: 0,
    uf: 0,
    cidade: 0,
  });

  const [getCep, setGetCep] = useState(0);

  function handleChange(e, innerData) {
    let target = e.currentTarget;

    target.name === "uf"
      ? setData({
        ...data,
        [target.name]: innerData || target.value,
        cidade: getFirstCity(target.value),
      })
      : target.name === "ufn"
        ? setData({
          ...data,
          [target.name]: innerData || target.value,
          cidaden: getFirstCity(target.value),
        })
        : setData({ ...data, [target.name]: innerData || target.value });

    if (
      data[target.name] &&
      data[target.name].length === 0 &&
      target.value === ""
    ) {
      setAlert({ ...showAlert, [target.name]: 1 });
    } else {
      clearAlert(e);
    }
  }

  function getFirstCity(value) {
    let result;
    for (let estado of estados.estados) {
      if (estado.sigla === value && estado.cidades && estado.cidades.length > 0)
        result = estado.cidades[0];
    }
    return result;
  }

  function handleCEP(e) {
    let nums = /\d/;
    let target = e.currentTarget;

    if (nums.test(target.value)) {
      let cep = mCEP(target.value);
      target.name === "cep" ? handleChange(e, cep) : handleChange(e);
      target.name === "cep" && setGetCep(1);

      return false;
    }

    if (data[target.name].length === 1 && target.value === "") {
      handleChange(e);

      return false;
    }

    return false;
  }

  function clearAlert(e) {
    let target = e.currentTarget;

    setAlert({ ...showAlert, [target.name]: 0 });
  }

  function submit(callback) {
    let invalid = isInvalid(data);

    //-- Diogo Voltar?
    if (!invalid) {
      // if (true) {
      let newData = Object.assign(data);
      // console.log(newData)
      if (newData) {
        for (let n in newData) {
          if (newData[n] && n !== "ip" && n !== "id")
            try {
              newData[n] = newData[n].toUpperCase();
            } catch (error) {
              console.log(newData[n]);
              console.log(error);
            }
        }

        send(
          {
            uri: uriList("pessoas"),
            content: { ...newData, proposta_uuid: props.proposta, },
            obj: newData,
            key: "endereco",
          },
          props.toggleStep,
          false,
          false,
          callback
        );

        for (let d in data) props.clienteDados[d] = data[d];
        // setData((previous) => ({ ...previous, [d]:  }));
      }

      return false;
    } else if (callback) callback(false);

    setAlert(invalid);
  }

  useEffect(() => {
    setLoading(true);

    for (let d in data)
      if (props.clienteDados[d])
        setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));

    setLoading(false);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const model = {
      cep: "",
      t_log: "rua",
      logradouro: "",
      complemento: "",
      numero: "",
      bairro: "",
      uf: "AC",
      cidade: "",
      ip: getCookie({ label: "ip" }),
      id: props.cliente,
    };

    if (data.cep.length === 9 && getCep) {
      setLoading(true);
      pureGetData({
        uri: `https://viacep.com.br/ws/${data.cep.replace("-", "")}/json/`,
        signal,
      })
        .then((resData) => {
          setGetCep(0);

          if (!resData)
            throw new Error("CEPError: CEP não encontrado pelo servidor");

          let logradouro = resData.logradouro;

          logradouro = logradouro.replace(" ", ",").split(",");

          setData({
            ...data,
            bairro: resData.bairro,
            cidade: resData.localidade,
            logradouro: logradouro[1],
            t_log: logradouro[0].toUpperCase(),
            uf: resData.uf,
          });

          setAlert({
            cep: 0,
            t_log: 0,
            logradouro: 0,
            complemento: 0,
            numero: 0,
            bairro: 0,
            uf: 0,
            cidade: 0,
          });
          setLoading(false);
        })
        .catch((e) => {
          setData({ ...model, cep: data.cep, cepReady: 1 });
          setGetCep(0);
          setLoading(false);
        });
    }

    return function cleanup() {
      abortController.abort();
    };
  }, [data, getCep]);

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>

      {!loading && (
        <>
          <div className="form">
            <Form
              estados={estados}
              logradouros={logradouros}
              data={data}
              changeData={handleChange}
              CEP={handleCEP}
              tipo_endereco={tipos_endereco}
              warning={showAlert}
            />
          </div>
          <Buttons
            goBack={() => goBack(data, "endereco", props.toggleStep)}
            submit={submit}
          />
        </>
      )}
      {loading && (
        <div style={{ textAlign: "center" }}>
          <br />
          <h2>Carregando dados...</h2>
        </div>
      )}
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

export default connect(mapStateToProps)(Address);
