import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { getCookie, getLocation } from "../../../helpers/general";
import { uriList } from "../../../helpers/config";
import { InputLabelAlert } from "../../layout";
import { swalError } from "../../swal";
import { bancoFornecedor, sendData } from "../../../helpers/auth";

import {
  mDate,
  mDateBD,
  formatFloat,
  formatCurrency,
} from "../../../helpers/general";
import * as moment from "moment";

let posicoes = [
  {
    value: "Aguardando Deferimento",
    nome: "Aguardando Deferimento de Averbação",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  {
    value: "Aguardando Pagamento",
    nome: "Aguardando Pagamento",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  {
    value: "Aguardando Pagto Saldo",
    nome: "Aguardando Pagto Saldo",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Aguardando Refin",
    nome: "Aguardando Refin",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Aguardando Saldo",
    nome: "Aguardando Saldo",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Assinando CCB",
    nome: "Assinando CCB",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  {
    value: "Assinando CCB Port",
    nome: "Assinando CCB Port",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Autorizando Portabilidade",
    nome: "Autorizando Portabilidade",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Averbada",
    nome: "Averbada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  {
    value: "Averbacao Solicitada",
    nome: "Averbacao Solicitada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  { value: "Pago", nome: "Pago" },
  {
    value: "Portabilidade Autorizada",
    nome: "Portabilidade Autorizada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Aprovada",
    nome: "Aprovada",
    visible: function (prop) {
      return bancoFornecedor() == "inbursa";
    },
  },
  {
    value: "Portabilidade Averbada",
    nome: "Portabilidade Averbada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Proposta Assinada",
    nome: "Proposta Assinada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() != "portabilidade";
    },
  },
  {
    value: "Proposta Port Assinada",
    nome: "Proposta Port Assinada",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  {
    value: "Saldo Recebido",
    nome: "Saldo Recebido",
    visible: function (prop) {
      return prop.operacao?.toLowerCase() == "portabilidade";
    },
  },
  { value: "Simulacao", nome: "Simulacao" },
];

function AlteracaoProposta({ ...props }) {
  const [agenteid, setAgenteId] = useState(props.detalhes.agente_id);
  const [codigoOrgao, setCodigoOrgao] = useState(props.detalhes.codigo_orgao);
  const [matricula, setMatricula] = useState(props.detalhes.matricula);
  const [tabelaList, setTabelaList] = useState([]);
  const [posicao, setPosicao] = useState(
    props.detalhes.posicao || "Aguardando Deferimento"
  );
  const [agentes, setAgentes] = useState(props.agentes);
  const [saldo_devedor, setSaldoDevedor] = useState(
    props.detalhes.saldo_devedor
  );
  const [parcela_original, setParcelaOriginal] = useState(
    props.detalhes.parcela_original
  );
  const [gravando, setGravando] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [cnpj_banco, setCnpjBanco] = useState(
    props.detalhes.cnpj_banco_original
  );
  const [parcelas_total, setParcelasTotal] = useState(
    parseInt(props.detalhes.parcelas_total)
  );
  const [vecto_primeira_parcela, setVectoPrimeiraParcela] = useState(
    props.detalhes.vecto_primeira_parcela &&
    moment(props.detalhes.vecto_primeira_parcela).format("DD/MM/YYYY")
  );
  const [vecto_ultima_parcela, setVectoUltimaParcela] = useState(
    props.detalhes.vecto_ultima_parcela &&
    moment(props.detalhes.vecto_ultima_parcela).format("DD/MM/YYYY")
  );
  const [contrato, setContrato] = useState(props.detalhes.contrato_original);
  const [tabela_id, setTabelaId] = useState(props.detalhes.tabela_id);

  const [iof, setIof] = useState(props.detalhes.iof);
  const [taxa_juros_mensal, setTaxaMensal] = useState(
    props.detalhes.taxa_juros_mensal
  );
  const [taxa_juros_anual, setTaxaAnual] = useState(
    props.detalhes.taxa_juros_anual
  );
  const [custo_efetivom, setCustoEfetivom] = useState(
    props.detalhes.custo_efetivom
  );
  const [custo_efetivoa, setCustoEfetivoa] = useState(
    props.detalhes.custo_efetivoa
  );
  const [custo_efetivo, setCustoEfetivo] = useState(
    props.detalhes.custo_efetivo
  );
  const [seguro, setSeguro] = useState(props.detalhes.seguro || 0);
  const [uf_beneficio, setUfBeneficio] = useState(props.detalhes.uf_beneficio);
  const [numero_portabilidade, setNumeroportabilidade] = useState(
    props.detalhes.numero_portabilidade
  );


  useEffect(() => {
    console.log("alteracaoProposta.useEffect: ", props.detalhes);
    getLocation();
    _getTabelas();
    console.log("alteracaoProposta.useEffect(1)");
  }, []);

  async function _getTabelas() {
    setTabelaList([]);
    await sendData({
      uri: uriList("listarTabelas"),
      content: {
        convenio_id: props.detalhes.convenio_id,
        operacao: "PORTABILIDADE",
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setTabelaList(res);
      })
      .catch((e) => {
        // alert('Erro ao obter Tabelas.\n' + e.message)
      });
  }

  const handleBlur = (e) => {
    let target = e.currentTarget;
    let name = target.name;
    let value = target.value;

    if (name == "iof") setIof(formatCurrency(formatFloat(value)));
    else if (name == "taxa_juros_mensal")
      setTaxaMensal(formatCurrency(formatFloat(value)));
    else if (name == "taxa_juros_anual")
      setTaxaAnual(formatCurrency(formatFloat(value)));
    else if (name == "custo_efetivom")
      setCustoEfetivom(formatCurrency(formatFloat(value)));
    else if (name == "custo_efetivoa")
      setCustoEfetivoa(formatCurrency(formatFloat(value)));
    else if (name == "seguro") setSeguro(formatCurrency(formatFloat(value)));
  };

  const handleClose = (gravou = false) => {
    if (gravou && props.onHandle) {
      console.log("alteracaoProposta.handleClose(): ", gravou);
      props.onHandle();
    } else if (props.onCloseEv) props.onCloseEv();
  };

  const handleGravar = () => {
    let [latitude, longitude] = getCookie({ label: "geolocation" })
      ? getCookie({ label: "geolocation" }).split(",")
      : ["", ""];

    setGravando(true);
    let content = {};

    content["uuid"] = props.proposta;
    if (posicao != props.detalhes.posicao) content["posicao"] = posicao;
    content["agente_id"] = agenteid;
    content["codigo_orgao"] = codigoOrgao;
    content["matricula"] = matricula;
    content["saldo_devedor"] = saldo_devedor;
    content["parcela_original"] = parcela_original;
    content["cnpj_banco_original"] = cnpj_banco;
    content["parcelas_total"] = parcelas_total;
    content["contrato"] = contrato;
    content["tabela_id"] = tabela_id;
    content["uf_beneficio"] = uf_beneficio;
    if (numero_portabilidade)
      content["numero_portabilidade"] = numero_portabilidade;

    if (vecto_primeira_parcela)
      content["vecto_primeira_parcela"] = mDateBD(vecto_primeira_parcela);
    if (vecto_ultima_parcela)
      content["vecto_ultima_parcela"] = mDateBD(vecto_ultima_parcela);

    //-- Só permite alterações para o convênio SICOOB
    if (props.detalhes.tipo_convenio == "SICOOB") {
      content["iof"] = formatFloat(iof);
      content["taxa_juros_mensal"] = formatFloat(taxa_juros_mensal);
      content["taxa_juros_anual"] = formatFloat(taxa_juros_anual);
      content["custo_efetivom"] = formatFloat(custo_efetivom);
      content["custo_efetivoa"] = formatFloat(custo_efetivoa);
      content["custo_efetivo"] = formatFloat(custo_efetivoa);
      content["seguro"] = formatFloat(seguro);
    }

    content["latitude"] = latitude;
    content["longitude"] = longitude;
    content["ip"] = getCookie({ label: "ip" });
    content["geolocation"] = JSON.stringify({ latitude, longitude });

    sendData({
      uri: uriList("alteracaoProposta"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        setGravando(false);
        handleClose(true);
      })
      .catch((e) => {
        setGravando(false);
        swalError({
          title: `Erro ao alterar Proposta! \n${e.data || e.message || e.erro}`,
        });
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    let valor = null;
    switch (name) {
      case "agente_id":
        setAgenteId(value);
        break;
      case "codigo_orgao":
        setCodigoOrgao(value);
        break;
      case "matricula":
        setMatricula(value);
        break;
      case "posicao":
        setPosicao(value);
        break;
      case "saldo_devedor":
        setSaldoDevedor(value);
        break;
      case "parcela_original":
        setParcelaOriginal(value);
        break;
      case "cnpj_banco":
        setCnpjBanco(value);
        break;
      case "parcelas_total":
        setParcelasTotal(parseInt(value));
        break;
      case "vecto_primeira_parcela":
        valor = mDate(value);
        setVectoPrimeiraParcela(valor);
        break;
      case "vecto_ultima_parcela":
        valor = mDate(value);
        setVectoUltimaParcela(valor);
        break;
      case "contrato":
        setContrato(value);
        break;
      case "iof":
        setIof(value);
        break;
      case "taxa_juros_mensal":
        setTaxaMensal(value);
        break;
      case "taxa_juros_anual":
        setTaxaAnual(value);
        break;
      case "custo_efetivom":
        setCustoEfetivom(value);
        break;
      case "custo_efetivoa":
        setCustoEfetivoa(value);
        break;
      case "seguro":
        setSeguro(value);
        break;
      case "tabela_id":
        setTabelaId(value);
        break;
      case "numero_portabilidade":
        setNumeroportabilidade(value);
        break;
      default:
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={true}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Alteração de Proposta</span>
          </Modal.Title>
        </Modal.Header>
        {/* <span style={{ fontSize: "12px", textAlign: "center", fontStyle: "italic" }}>{props.detalhes.uuid}</span> */}
        <Modal.Body className="maxWidth750">
          <>
            {gravando && (
              <div style={{ textAlign: "center", width: "100%" }}>
                <br />
                <h5 style={{ opacity: "60%" }}>Aguarde, gravando dados...</h5>
              </div>
            )}
            <div className="row">
              <div className="form-group col-md-6">
                <InputLabelAlert
                  type="tel"
                  label="Matrícula"
                  id="matricula"
                  value={matricula}
                  handle={handleChange}
                  // onBlur={handleBlur}
                  // warning={warning.matricula}
                  length="50"
                  minLength={5}
                  focus={true}
                />
              </div>

              <div className="form-group col-md-6">
                <InputLabelAlert
                  type="tel"
                  label="Código do Órgão"
                  id="codigo_orgao"
                  value={codigoOrgao}
                  handle={handleChange}
                  // onBlur={handleBlur}
                  // warning={warning.matricula}
                  length="50"
                  minLength={5}
                  focus={false}
                />
              </div>
            </div>

            {(["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0) &&
              <div className="row">
                <div
                  // className={`form-group ${
                  //   props.detalhes.tipo_convenio == "INSS"
                  //     ? "col-md-6"
                  //     : "col-md-12"
                  // }`}
                  className={`form-group ${"col-md-12"}`}
                >
                  <label className="m-0 font-90">Status</label>
                  <select
                    className="form-control"
                    defaultValue={posicao}
                    onChange={(e) => setPosicao(e.target.value)}
                  >
                    {posicoes.map((item, key) => {
                      if (!item.visible || item.visible(props.detalhes))
                        return (
                          <option key={key} value={item.value}>
                            {item.nome}
                          </option>
                        );
                    })}
                  </select>
                </div>
              </div>}

            {(["AGENTE", "OPERADOR PARCEIRO"].indexOf(props.user?.tipo) < 0) &&
              <div>
                <label className="m-0 font-90">Agente</label>
                <select
                  className="form-control"
                  defaultValue={agenteid}
                  onChange={(e) => setAgenteId(e.target.value)}
                >
                  {agentes &&
                    agentes.map((item, key) => {
                      if (!agenteid) {
                        setAgenteId(item.id);
                      }
                      return (
                        <option key={key} value={item.id} selected={item.id == agenteid}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>}

            {["refinanciamento", "portabilidade"].indexOf(
              props.detalhes.operacao.toLowerCase()
            ) >= 0 && (
                <div className="row">
                  <div className="form-group col-md-12">
                    <InputLabelAlert
                      type="tel"
                      label="Contrato Original"
                      id="contrato"
                      value={contrato}
                      handle={handleChange}
                      tipText={"Número do contrato original"}
                      focus={false}
                    />
                  </div>
                </div>
              )}
            {props.detalhes.operacao.toLowerCase() == "portabilidade" &&
              props.detalhes.posicao.toLowerCase() == "saldo recebido" && (
                <div className="row">
                  <div className="form-group col-md-12">
                    <label htmlFor="tabela_id" className="no-margin">
                      Tabela
                    </label>
                    <select
                      defaultValue={tabela_id}
                      onChange={handleChange}
                      name="tabela_id"
                      id="tabela_id"
                      style={{
                        textAlign: "left",
                      }}
                      className="form-control custom-select"
                    >
                      {tabelaList ? (
                        tabelaList.map((tabela, index) => (
                          <option
                            key={`tabela_id-${tabela.id}`}
                            title={tabela.nome}
                            value={tabela.id}
                            selected={tabela.id == tabela_id}
                          >
                            {tabela.nome}
                          </option>
                        ))
                      ) : (
                        <option>Carregando...</option>
                      )}
                    </select>
                  </div>
                </div>
              )}

            {props?.detalhes?.tipo_convenio == "SICOOB" && (
              <>
                <div className="row">
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="IOF"
                      id="iof"
                      cifrao={true}
                      value={iof}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Iof"}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="Tx Juros Mensal"
                      id="taxa_juros_mensal"
                      cifrao={false}
                      value={taxa_juros_mensal}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Taxa de Juros Mensal"}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="Tx Juros Anual"
                      id="taxa_juros_anual"
                      cifrao={false}
                      value={taxa_juros_anual}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Taxa de Juros Anual"}
                      focus={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="Custo Mensal"
                      id="custo_efetivom"
                      cifrao={false}
                      value={custo_efetivom}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Custo Efetivo Mensal"}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="Custo Anual"
                      id="custo_efetivoa"
                      cifrao={false}
                      value={custo_efetivoa}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Custo Efetivo Anual"}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <InputLabelAlert
                      type="numeric"
                      label="Seguro"
                      id="seguro"
                      cifrao={false}
                      value={seguro}
                      step="0.010"
                      handle={handleChange}
                      onBlur={handleBlur}
                      tipText={"Seguro"}
                      focus={false}
                    />
                  </div>
                </div>
              </>
            )}
            {props?.detalhes?.operacao == "PORTABILIDADE" && (
              <>
                <div
                  className="row"
                  style={{
                    marginTop: "10px",
                    backgroundColor: "silver",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      margin: "0px",
                      textAlign: "center",
                      opacity: "0.6",
                      fontWeight: "bold",
                    }}
                  >
                    Dados da Portabilidade
                  </span>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="numeric"
                      label="Saldo Devedor"
                      id="saldo_devedor"
                      cifrao={true}
                      value={saldo_devedor}
                      step="0.010"
                      handle={handleChange}
                      tipText={
                        "Saldo Devedor(Portabilidade ou Refinanciamento)"
                      }
                      focus={false}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="numeric"
                      label="Parcela Original"
                      id="parcela_original"
                      cifrao={true}
                      value={parcela_original}
                      step="0.010"
                      handle={handleChange}
                      tipText={"Valor da parcela do contrato original"}
                      focus={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="tel"
                      label="CNPJ Banco"
                      id="cnpj_banco"
                      value={cnpj_banco}
                      handle={handleChange}
                      // onBlur={handleBlur}
                      // warning={warning.matricula}
                      length="50"
                      minLength={5}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="numeric"
                      label="Prazo Restante"
                      id="parcelas_total"
                      cifrao={false}
                      value={parcelas_total}
                      step="1"
                      handle={handleChange}
                      tipText={"Parcelas restantes do contrato original"}
                      focus={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="tel"
                      label="Primeiro Vencimento"
                      id="vecto_primeira_parcela"
                      cifrao={false}
                      value={vecto_primeira_parcela}
                      handle={handleChange}
                      onChange={vecto_primeira_parcela}
                      focus={false}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <InputLabelAlert
                      type="tel"
                      label="Último Vencimento"
                      id="vecto_ultima_parcela"
                      cifrao={false}
                      value={vecto_ultima_parcela}
                      handle={handleChange}
                      onChange={vecto_ultima_parcela}
                      focus={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <InputLabelAlert
                      type="tel"
                      label="Número Portabilidade"
                      id="numero_portabilidade"
                      cifrao={false}
                      value={numero_portabilidade}
                      handle={handleChange}
                      onChange={numero_portabilidade}
                      focus={false}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handleGravar} disabled={!agentes}>
            Gravar
          </Button>
          <Button color="secondary" onClick={() => handleClose(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    detalhes: state.dados.detalhes,
  };
}

export default connect(mapStateToProps)(AlteracaoProposta);
